<template>
    <!--
        TODO:
        - edit make pass lost
    -->
    <v-container class="pass-builder" :class="cardTypeClass" :style=mainStyle>
        <!-- {{ applResouce.templateData.templateName }} -->
        <!-- {{useMongoDB}} -->
        <v-form v-on:submit.prevent :disabled=effectiveReadonly>
        <v-row v-if="applResouce && viewMode == false" class="mt-0 mb-1" style="width:100%;">
            <v-col class="pa-0" cols="5">
                <v-text-field width="300px" :disabled=readOnlyMode clearable hide-details v-model="applResouce.templateData.templateName" label="Name">
                </v-text-field>
            </v-col>
            <v-col v-if="debugMode" cols="7" class="pt-3">
                <v-btn @click="loadClick()">Load</v-btn>
                <v-btn @click="saveClick()">Save</v-btn>
                <v-btn @click="newClick()">New</v-btn>
                <template v-if="server == 'stage'"> *</template>
                <!-- <v-btn @click="showHtmlClick()">HTML</v-btn> -->
                <!-- {{currentPassID}} - {{currentPass}} - {{activeObject}} -->
            </v-col>
            <template v-else>
                <v-col cols="5" class="mb-2">
                    <template v-if="useMongoDB == false">
                        <v-btn :disabled=readOnlyMode  @click="loadClick()">Load</v-btn>
                    <template v-if="server == 'stage'"> *</template>
                    <!-- <v-btn id="menu" @click="menu = !menu">Load From</v-btn>
                    <KCSearchableSelect @select="onLoadFrom" attachID="menu" :height=200 v-model="menu" :listData=headers></KCSearchableSelect> -->
                    </template>
                    <template v-else>
                        <template v-if="isUpdateMode==false">
                        <v-btn v-if="loadItems" :disabled=readOnlyMode small @click="loadClick()" class="mr-2"><v-icon small>folder_open</v-icon>Load</v-btn>
                        <v-btn small @click="importClick()" class="mr-2"><v-icon small>file_download</v-icon>Import</v-btn>
                        </template>
                        <v-btn v-if="isUpdateMode" small @click="exportClick()" class="mr-2"><v-icon small>file_upload</v-icon> Export</v-btn>
                        <v-btn small @click="newClick()" class="mr-2"><v-icon small>autorenew</v-icon> Reset</v-btn>
                        <div style="display:none;">
                        <v-file-input  ref="localFileInput"  accept=".json" class="pt-1 shrink" v-model="localFileInput" label="ZIP" prepend-icon="upload_file"
                            @change="checkUpload"></v-file-input>
                        </div>
                        <!-- <v-btn @click="preferenceClick()">Preference</v-btn> -->
                    </template>
                </v-col>
                <!-- {{ showQRCode }} -->
                <!-- xxxx{{ qrcodeUrl }} -->
                <v-col v-if="showQRCode && qrcodeUrl!=null" style="position:relative;" cols="2">
                    <KCQRCodeView ref="qrcodeView" id="qrcode" @click="qrcodeClick('open')" :data=qrcodeUrl style="top:0px;position:absolute;right:0px;"></KCQRCodeView>                            
                    <div @click="qrcodeClick('open')" style="position:absolute;bottom: -60px;
    right: 0px;cursor: pointer;opacity:0.7;
    font-size: small;">Click here to download</div>
                    <!-- <div style="position:absolute;">
                        <img src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png">
                    </div> -->
                    <v-menu
                    v-model="qrcodeMenu"
                    attach="#qrcode"
                    absolute
                    :close-on-content-click="false"
                    :nudge-width=400
                    :max-width=400
                    :position-x="-220"
                    :position-y=0>      
                    <v-card class="px-2 pb-3">
                        <v-card-title>Pass's Url</v-card-title>
                        <v-card-text>{{qrcodeUrl}}</v-card-text>
                        <v-card-actions>
                            <v-btn @click="qrcodeClick('copy')" small><v-icon small>content_copy</v-icon> Copy url</v-btn>
                            <v-btn @click="qrcodeClick('download')" small><v-icon small>download</v-icon> Download Image</v-btn>
                        </v-card-actions>
                        
                    </v-card>
                    </v-menu>
                </v-col>
            </template>
        </v-row>
        <v-row :style="loadingStyle" class="mt-2 pb-6" v-if="isAddThisPassMode == true && debugMode==false && lightMode == false">
            <!-- <v-form :disabled=readOnlyMode ref="selectForm" v-model="isFormValid"> -->
            <v-col class="pa-0" cols="4">
                <v-form  ref="qrCodeForm" :disabled=effectiveReadonly v-model="isFormValid">
                <v-text-field width="300px" :disabled=true :readonly=true :rules=qrcodeColumnRule hide-details v-model="applResouce.templateData.qrcodeColumn" label="QRCodeColumn">
                </v-text-field>
                </v-form>
            </v-col>
            <v-col class="pt-0 pl-2 " cols="4">
                <v-switch v-model="showOptions" xcolor="success" hide-details>
                    <template v-slot:label>
                        <span v-if="showOptions">Hide options</span>
                        <span v-else>Show options</span>
                    </template>
                </v-switch>
                <!-- <v-btn :disabled=readOnlyMode small @click="moreOptionClick()"><v-icon small>more_vert</v-icon></v-btn> -->
            </v-col>
            <div style="width: 100%;border: solid 1px lightgray;padding: 5px;position: relative;" v-if="showOptions">
                <KCComputedInput :readOnlyMode=effectiveReadonly ref="messageInput" v-model=applResouce.templateData.messageData :needName=false label="Message" :headers=Headers :sampleData=SampleData></KCComputedInput>
                <KCComputedInput :readOnlyMode=effectiveReadonly ref="linkInput" v-model=applResouce.templateData.linkData :needName=false label="Link" :headers=Headers :sampleData=SampleData></KCComputedInput>
                <KCComputedInput :readOnlyMode=effectiveReadonly ref="backsideMessage" v-model=applResouce.templateData.backsideMessage :needName=false label="Personalized Message on the Backside" :headers=Headers :sampleData=SampleData></KCComputedInput>
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="position: absolute;bottom: 3px;right: 3px;">
                            <a href="https://mindfiresupport.freshdesk.com/support/solutions/articles/73000638878" target="blank">
                                <v-icon class="ml-2">help</v-icon>
                            </a>
                        </div>
                    </template>
                    Learn how to build a clickable link here
                </v-tooltip>
            </div>

            <!-- </v-form> -->
        </v-row>
        <v-row class="mt-2 pb-6" v-if="isMindFireMode == true && debugMode==false">
            <v-col class="pa-0" cols="3">
                <v-form  ref="qrCodeForm" :disabled=readOnlyMode v-model="isFormValid">
                <v-text-field width="300px" :rules=qrcodeColumnRule clearable hide-details v-model="applResouce.templateData.qrcodeColumn" label="QRCodeColumn">
                </v-text-field>
                </v-form>
            </v-col>
        </v-row>   
        <v-row :style="loadingStyle" class="mt-1 card-content">
            <div class="mr-3 pass-viewer"
                style="width:330px;position: relative;    transform-style: preserve-3d; perspective: 1000px;">
                <div v-if="cardLoading" class="px-3"><v-progress-linear 
                    indeterminate
                    height="3"
                ></v-progress-linear></div>
                <div style="position:relative;" v-show="cardLoading==false">
                <div class="pass-background-image-container" v-if="applResouce.chkBackgroundImage">
                    <div :style="passStyle" class="pass-background-image" :class="[applResouce.templateData.chkBlurBackgroundImage?'':'no-blur']" ></div>
                </div>
                <div class="pass-background-image-container" v-if="applResouce.chkBackgroundVideo">
                    <div :style="passStyle" class="pass-background-image no-blur"></div>
                </div>

                <Transition mode="out-in">
                    <div v-if="showingFront" key="passFront" :style="passColorStyle" class="appl-pass pass-front">
                        <div class="pass-header">
                            <div @click="objClick('logo',null,$event)" :class="[activeObject == 'logo'? 'active':'']"
                                class="pass-logo clickable" :style="logoStyle"
                                style="color: transparent; display: inline-table; height: 42px;">
                                <img ref="logoImage" :style=logoStyle :src="applResouce.logo" style="height: 42px;">
                            </div>
                            <div @click="objClick('logoText',null,$event)"
                                :class="[activeObject == 'logoText'? 'active':'']" class="pass-logoText clickable"
                                :style="logoTextStyle" style="display: inline-block; height: 42px;width:100%;">
                                <div style="padding:10px;">{{applPassData.logoText}}</div>
                            </div>
                            <div  v-if="isMindFireMode == false" class="pass-headerFields">
                                <div @click="objClick('headerFields',i,$event)"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `headerFields-${i}`? 'active':'']"
                                    class="clickable" v-for="(field,i ) in applPassData.coupon.headerFields" :key="i"
                                    style="display: inline-block; padding-top:5px;height: 42px;">
                                    <div v-if="field.label || field.value">
                                        <div class="pass-label label-color" style="text-align: right;">{{field.label}}
                                        </div>
                                        <div class="pass-value value-color" :style="headerStyle()"
                                            style="text-align: right;">{{field.value}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Header
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="cardTypeClass.includes('coupon')">
                            <div @click="objClick('primary',null,$event)"
                                :class="[activeObject == 'primary'? 'active':'']" class="pass-stripSection clickable"
                                style="height: 123px; margin-left: -16px; margin-right: -16px;" :style="applStripImage">
                                <div class="pass-primary">
                                    <div
                                        v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                        <div :style="primaryStyle()" class="pass-primary-value value-color">
                                            {{applPassData.coupon.primaryFields[0].value}}</div>
                                        <div class="pass-primary-label value-color">
                                            {{applPassData.coupon.primaryFields[0].label}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Primary
                                    </div>
                                </div>
                            </div>
                            <div class="pass-secondary" style="display: flex;justify-content: space-between;">
                                <div class="clickable" @click="objClick('morefield',i,$event)"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `morefield-${i}`? 'active':'']"
                                    v-for="(field,i ) in moreFields" :key="i">
                                    <div v-if="field.label || field.value" :class="[i==2? 'text-left':'']">
                                        <div class="pass-label label-color">{{field.label}}</div>
                                        <div :style="valueStyle(moreFields)" class="pass-value value-color">
                                            {{field.value}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Secondary
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="isMindFireMode">
                            <div @click="objClick('primary',null,$event)"
                                :class="[activeObject == 'primary'? 'active':'']" class="pass-stripSection clickable"
                                style="height: 89px; margin-left: -16px; margin-right: -16px;" :style="applStripImage">
                                <div class="pass-primary">
                                    <div
                                        v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                        <div :style="primaryStyle()" class="pass-primary-value value-color">
                                            {{applPassData.coupon.primaryFields[0].value}}</div>
                                        <div class="pass-primary-label value-color">
                                            {{applPassData.coupon.primaryFields[0].label}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty">
                                        Primary
                                    </div>
                                </div>
                            </div>
                            <div class="pass-secondary" style="display: flex;justify-content: space-between;">
                                <div @click="objClick('secondary',0,$event)" class="pt-1 clickable"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `secondary-0`? 'active':'', 0%3 == 2? 'right':'']">
                                    <div v-if="applPassData.coupon.secondaryFields[0].label || applPassData.coupon.secondaryFields[0].value" :class="[0==2? 'text-left':'']">
                                        <div class="pass-label label-color mb-n1">{{applPassData.coupon.secondaryFields[0].label}}</div>
                                        <div class="pass-value value-color">{{applPassData.coupon.secondaryFields[0].value}}</div>
                                    </div>
                                    <div v-else class="pass-field-empty" :style="{opacity: 0==1?0:1}">
                                        Secondary
                                    </div>
                                </div>
                            </div>
                            <div v-if="hideAuxilary==false" class="pass-auxiliary pt-1">
                                    <div @click="objClick('auxiliary',i,$event)"
                                        :class="[`${activeObject}-${activeObjectIndex}` == `auxiliary-${i}`? 'active':'', i%3 == 2? 'right':'']"
                                        class="clickable" v-for="(field,i ) in applPassData.coupon.auxiliaryFields"
                                        :key="i">
                                        <div v-if="field.label || field.value">
                                            <div class="pass-label label-color mb-n1">{{field.label}}</div>
                                            <div class="pass-value value-color">{{field.value}}</div>
                                        </div>
                                        <div v-else class="pass-field-empty">
                                            Send message
                                        </div>
                                    </div>
                                </div>
                        </template>                        
                        <template v-if="isAddThisPassMode">
                            <!-- chkThumbnail={{ applResouce.chkThumbnail }} -->
                            <div v-if="applResouce.templateData.showWelcomeMessage">
                                <template v-if="applResouce.chkThumbnail">
                                    <div @click="objClick('primary',null,$event)" :class="[activeObject == 'primary'? 'active':'']" class="clickable">
                                    <div style="width: 70%;display: inline-block;height: 40px;">
                                        <div class="pt-2">
                                            <div class="pass-primary">
                                                <div
                                                    v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                                    <div class="pass-label label-color mb-n1">
                                                        {{applPassData.coupon.primaryFields[0].label}}</div>
                                                    <div :style="primaryStyle()" class="pass-value value-color">
                                                        {{applPassData.coupon.primaryFields[0].value}}</div>
                                                </div>
                                                <div v-else class="pass-field-empty">
                                                    Primary
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :style="thumbnailStyle" style="color: transparent; display: inline-block; height: 42px;float:right;">
                                        <template v-if="isCheckThumbNail">
                                            <img v-if="applResouce.useLogoAsThumbnail==false" :src="applResouce.thumbnail" height=42 />
                                            <img v-else :src="applResouce.logo" height=42 />
                                        </template>
                                        <template v-else>
                                            <div class="pass-field-empty" style="color: black;font-size: 9px;">
                                                Thumbnail
                                            </div>
                                        </template>
                                    </div>
                                    </div>
                                </template>
                                <template v-else>
                                     <div @click="objClick('primary',null,$event)"
                                        :class="[activeObject == 'primary'? 'active':'']" class="pass-stripSection clickable"
                                        style="heightx: 89px; margin-left: -16px; margin-right: -16px;" :style="applStripImage">
                                        <div class="pass-primary strip-image">
                                            <div
                                                v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                                <div :style="primaryStyle('strip')" class="pass-primary-value value-color">
                                                    {{applPassData.coupon.primaryFields[0].value}}</div>
                                                <div class="pass-primary-label value-color">
                                                    {{applPassData.coupon.primaryFields[0].label}}</div>
                                            </div>
                                            <div v-else class="pass-field-empty">
                                                Primary
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="pass-secondary">
                                    <div @click="objClick('secondary',i,$event)" class="pt-1 clickable"
                                        :class="[`${activeObject}-${activeObjectIndex}` == `secondary-${i}`? 'active':'', i%3 == 2? 'right':'']"
                                        v-for="(field,i ) in applPassData.coupon.secondaryFields" :key="i">
                                        <div v-if="field.label || field.value" :class="[i==2? 'text-left':'']">
                                            <div class="pass-label label-color mb-n1">{{field.label}}</div>
                                            <div class="pass-value value-color">{{field.value}}</div>
                                        </div>
                                        <div v-else class="pass-field-empty" :style="{opacity: i==1?0:1}">
                                            Secondary
                                        </div>
                                    </div>
                                </div>
                                <div v-if="hideAuxilary==false" class="pass-auxiliary pt-1">
                                    <div @click="objClick('auxiliary',i,$event)"
                                        :class="[`${activeObject}-${activeObjectIndex}` == `auxiliary-${i}`? 'active':'', i%3 == 2? 'right':'']"
                                        class="clickable" v-for="(field,i ) in applPassData.coupon.auxiliaryFields"
                                        :key="i">
                                        <div v-if="field.label || field.value">
                                            <div class="pass-label label-color mb-n1">{{field.label}}</div>
                                            <div class="pass-value value-color">{{field.value}}</div>
                                        </div>
                                        <div v-else class="pass-field-empty">
                                            Auxiliary
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <template>
                                     <div @click="objClick('primary',null,$event)"
                                        :class="[activeObject == 'primary'? 'active':'']" class="pass-stripSection clickable"
                                        style="heightx: 89px; margin-left: -16px; margin-right: -16px;" :style="applStripImage">
                                        <div class="pass-primary strip-image">
                                            <!-- <div
                                                v-if="applPassData.coupon.primaryFields[0].value || applPassData.coupon.primaryFields[0].label">
                                                <div :style="primaryStyle('strip')" class="pass-primary-value value-color">
                                                    {{applPassData.coupon.primaryFields[0].value}}</div>
                                                <div class="pass-primary-label value-color">
                                                    {{applPassData.coupon.primaryFields[0].label}}</div>
                                            </div>
                                            <div v-else class="pass-field-empty">
                                                Primary
                                            </div> -->
                                            <div style="font-size:xx-large;" v-if="applResouce.chkStripImage == false" class="pass-field-empty">
                                                Banner
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div class="pass-barcode-section">
                            <div @click="objClick('barcode',null,$event)"
                                :class="[activeObject == 'barcode'? 'active':'']" class="pass-barcode clickable">
                                <div :style="{'background-color':applPassData.barcode.format!='NoBardcode'?'white':'transparent'}"
                                    style="padding: 8px;border-radius: 4px;background-color: rgb(255, 255, 255);">
                                    <div v-if="applPassData.barcode.format!='NoBardcode'">
                                        <div>
                                            <span style="color: transparent; display: flex; justify-content: center;">
                                                <img :src="barcodeImage" style="max-height: 100px;">
                                            </span>
                                        </div>
                                        <template v-if="isAddThisPassMode==false">
                                        <div v-if="applPassData.barcode.altText" class="pass-barcode-alt-text">
                                            {{applPassData.barcode.altText}}
                                        </div>
                                        </template>
                                    </div>
                                    <div v-else class="pass-field-empty"
                                        style="min-width: 380px;padding-top: 25px;min-height: 80px;padding-left: 100px;">
                                        Barcode
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pass-info">
                            <span @click="flipPass()" href="#" class="pass-infoButton" style="cursor:pointer;">ℹ</span>
                        </div>
                    </div>
                    <div v-if="showingFront==false" key="passBack" :style="passColorStyle" class="appl-pass pass-back">
                        <div class="pass-backSection pt-5">
                            <div class="pass-backFields">
                                <template v-if="(isAddThisPassMode == false)">
                                <div  @click="objClick('backFields',i,$event)"
                                    :class="[`${activeObject}-${activeObjectIndex}` == `backFields-${i}`? 'active':'']"
                                    class="clickable" v-for="(field,i ) in applPassData.coupon.backFields" :key="i">
                                    <div v-if="field.label || field.value">
                                        <div class="pass-label value-color">{{field.label}}</div>
                                        <div v-if="hasATag(field.value) == false" class="pass-value value-color">{{field.value}}</div>
                                        <div v-else v-html="field.value" class="pass-value value-color disable-a"></div>
                                    </div>
                                    <!-- <div v-else class="pass-field-empty">
                                        BackField
                                    </div> -->
                                </div>
                                </template>
                                <template v-if="(isAddThisPassMode == true)">
                                <div  @click="objClick('backFields-addThisPass',0,$event)"
                                    :class="[`${activeObject}` == `backFields-addThisPass`? 'active':'']"
                                    class="clickable add-this-pass">
                                    <div v-if="(applResouce.backFields.phone || applResouce.backFields.email)" class="pass-label label-color">CONTACT US:</div>
                                    <div ref="backContact" class="pass-value value-color">
                                        <span v-if="applResouce.backFields.phone">-P:<span
                                            class="href">{{applResouce.backFields.phone}}</span><br></span>
                                        <span v-if="applResouce.backFields.email">-E:<span
                                            class="href">{{applResouce.backFields.email}}</span></span>
                                    </div>                                            
                                    <div v-if="applResouce.backFields.address" class="pass-label label-color">LOCATE US:</div>
                                    <div v-if="applResouce.backFields.address" ref="backAddress" class="pass-value value-color">-A:<span
                                            class="href">{{applResouce.backFields.address}}</span><br>
                                            <span v-if="applResouce.backFields.surrounding">({{applResouce.backFields.surrounding}})</span>
                                    </div>
                                    <div v-if="(applResouce.backFields.website || applResouce.backFields.link1 || applResouce.backFields.link2)" class="pass-label label-color">VISIT US:</div>
                                    <div ref="backLinks" class="pass-value value-color">
                                        <div v-if="(applResouce.backFields.website && applResouce.backFields.website_name)">
                                            - <a :href="applResouce.backFields.website"
                                            target="_blank">{{applResouce.backFields.website_name}}</a><br>
                                        </div>
                                        <div v-if="(applResouce.backFields.link1 && applResouce.backFields.link1_name)">
                                        - <a :href="applResouce.backFields.link1"
                                            target="_blank">{{applResouce.backFields.link1_name}}</a><br>
                                        </div>
                                        <div v-if="(applResouce.backFields.link2 && applResouce.backFields.link2_name)">
                                        - <a :href="applResouce.backFields.link2"
                                            target="_blank">{{applResouce.backFields.link2_name}}</a><br>
                                        </div>
                                    </div>
                                    <template v-if="applResouce.backFields.more">
                                        <div class="pass-label label-color">MORE:</div>
                                        <div ref="backMore" class="pass-value value-color" v-html="renderMoreField">
                                        </div>
                                    </template>
                                    <div v-if="applResouce.backFields.html" style="overflow:hidden">
                                        <div style="height:300px;"> 
                                            <iframe width="100%" height="300px" class="scaled-frame" :srcdoc="applResouce.backFields.html" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                </template>
                            </div>
                        </div>
                        <div class="pass-header">
                            <div style="float:right;"><button @click="flipPass()"><span style="text-shadow: 1px 1px 1px white;">Done</span></button></div>
                        </div>
                    </div>
                </Transition>
                </div>
            </div>
            <Transition name="slide">
            <div v-if="activeObject!=null" class="mr-3">
                
                <div class="properties" style="position:relative;overflow: auto;">
                    <div @click="activeObject=null" style="cursor:pointer;position:absolute;top:8px;right:15px;color:white;">x</div>
                    <div v-if="activeObject==null" style="padding-top: 200px;padding-left: 10px;padding-right: 10px;">
                        <div class="center">
                            <p>Select a field on the pass to edit the settings of it.</p>
                        </div>
                    </div>
                    <div v-if="activeObject=='logo'">
                        <div class="prop-line prop-title">Logo</div>
                        <!-- <div class="prop-line">
                        <v-text-field v-model="applResouce.logo" label="Logo">
                        </v-text-field>
                    </div> -->
                        <div class="prop-line">
                            <div style="position:relative;" class="pa-5">
                                <v-img class="clickable" contain :width=logoWidth style="margin:auto;" @click="showCropUI('logo')" :src=applResouce.logo>
                                </v-img>
                                <v-icon v-show="cropEditImage['logo']" @click="showCropUI('logo',true)"
                                        style="position: absolute;bottom: 23px;right: 95px;cursor: pointer;font-size: 15px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    

                            </div>
                            <div style="font-size: 0.9em;">
                                <p class="pa-1">Logos are a strict requirement for a wallet pass. You can upload a
                                    square or rectangular logo. The recommended dimensions for a rectangular logo are
                                    480 pixels wide by 150 pixels high. A square logo should be 150 pixels wide by 150
                                    pixels high.</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeObject=='thumbnail'">
                        <div class="prop-line prop-title">Thumbnail</div>
                        <div class="prop-line pb-0">
                            <div class="prop-line thumbnail-image-checkbox" style="padding: 0px 10px;">
                                <v-checkbox style="display:inline-block;width:50%;" hide-details dense v-model="applResouce.chkThumbnail" label="Thumbnail">
                                </v-checkbox>
                                <v-checkbox  style="display:inline-block;width:50%;" hide-details dense v-model="applResouce.chkStripImage" label="Strip image">
                                </v-checkbox>
                            </div>
                        </div>
                        <div v-if="isCheckThumbNail" class="prop-line pt-0">
                            <div style="position:relative;" class="pa-5">
                                <v-img class="clickable" contain width="80%" style="margin:auto;" @click="showCropUI('thumbnail')"
                                    :src=applResouce.thumbnail></v-img>
                                    <v-icon v-show="cropEditImage['thumbnail']" @click="showCropUI('thumbnail',true)"
                                        style="position: absolute;bottom: 23px;right: 50px;cursor: pointer;font-size: 18px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    
                            </div>
                            <div style="font-size: 0.9em;">
                                <p class="pa-1">Thumbnail are a optional requirement for a wallet pass. You can upload a
                                    square or rectangular logo. The recommended dimensions for a rectangular logo are
                                    480 pixels wide by 150 pixels high. A square logo should be 150 pixels wide by 150
                                    pixels high.</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeObject=='logoText'">
                        <div class="prop-line prop-title">Logo Text</div>
                        <div class="prop-line">
                            <v-text-field :disabled=editMode counter :maxlength="this.maxTextLength('logo')" v-model="applPassData.logoText" label="Text">
                            </v-text-field>
                        </div>
                    </div>
                    <div v-if="activeObject=='headerFields'">
                        <KCPassFieldInput :hideKey=true maxLabel=15 v-model="applPassData.coupon.headerFields[activeObjectIndex]"
                            title="Header"></KCPassFieldInput>
                    </div>
                    <div v-if="activeObject=='primary'">
                        <template v-if="applResouce.templateData.showWelcomeMessage==true">
                            <KCPassFieldInput v-model="applPassData.coupon.primaryFields[0]" title="Primary" maxLabel=45
                                maxValue=45 :hideLabel="isAddThisPassMode" :hideKey=true></KCPassFieldInput>
                        </template>
                        <templale v-else>
                            <div class="prop-line prop-title">Primary</div>
                            <!-- <KCPassFieldInput v-model="applPassData.coupon.primaryFields[0]" title="Primary" maxLabel=45
                                maxValue=45 :hideLabel="isAddThisPassMode" :hideKey=true></KCPassFieldInput> -->
                        </templale>
                        <div v-if="applPassData.coupon.primaryFields[0].value ==''" style="padding: 0px 15px; font-size: small;font-style: italic;color: firebrick;">
                            Note: Require primary value.
                        </div>
                        <div class="prop-line strip-image-checkbox" style="padding: 0px 10px;">
                            <!-- <v-checkbox hide-details dense v-model="applResouce.chkStripImage" label="Use strip image">
                            </v-checkbox> -->
                            <v-checkbox v-if="isAddThisPassMode==false" style="display:inline-block;width:50%;" hide-details dense v-model="applResouce.chkThumbnail" label="Thumbnail">
                            </v-checkbox>
                            <v-checkbox :disabled="applResouce.chkBackgroundImage" style="display:inline-block;width:50%;" hide-details dense v-model="applResouce.chkStripImage" label="Banner image">
                            </v-checkbox>
                        </div>
                        <div v-if="applResouce.chkBackgroundImage" style="padding: 0px 15px; font-size: small;font-style: italic;color: firebrick;" >
                            Note: Banner image is disabled by using image background.
                        </div>
                        <div v-if="applResouce.chkStripImage" class="ma-4 strip-image-image"
                            style="border: solid 1px #c3bcbc;border-radius: 10px;">
                            <div class="prop-line">
                                <div  style="position:relative;" >
                                    <v-img height="105px" contain class="clickable" @click="showCropUI('stripImage')" :src=applResouce.stripImage>
                                    </v-img>
                                    <v-icon v-show="cropEditImage['stripImage']" @click="showCropUI('stripImage',true)"
                                        style="position: absolute;bottom: 4px;right: 8px;cursor: pointer;font-size: 18px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    

                                </div>
                            </div>
                        </div>
                        <div v-if="isCheckThumbNail && isAddThisPassMode==false"  class="ma-4 "
                            style="border: solid 1px #c3bcbc;border-radius: 10px;">
                            <div class="prop-line">
                                <div  style="position:relative;">
                            <v-img class="clickable" contain width="80%" style="margin:auto;" @click="showCropUI('thumbnail')"
                                :src=applResouce.thumbnail></v-img>
                                <v-icon v-show="cropEditImage['thumbnail']" @click="showCropUI('thumbnail',true)"
                                    style="position: absolute;bottom: 4px;right: 35px;cursor: pointer;font-size: 18px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    
                                </div>                                    
                            </div>                                    
                        </div>                        
                    </div>
                    <div v-if="activeObject=='morefield'">
                        <!-- <KCPassFieldInput v-model="applPassData.coupon.secondaryFields[activeObjectIndex]" title="Secondary"></KCPassFieldInput> -->
                        <KCPassFieldInput :addButton="moreFields.length<3?true:false" @addClick="addClick"
                            :maxLabel="maxLabel(activeObjectIndex)" maxValue=100 v-model="moreFields[activeObjectIndex]"
                            title="Secondary" :hideLabel="isAddThisPassMode" :hideKey=true></KCPassFieldInput>
                        <div v-if="moreFields.length > 1">
                            <v-icon @click="deleteMoreField(activeObjectIndex)"
                                style="position: absolute;bottom: 10px;right: 10px;cursor: pointer;">delete</v-icon>
                        </div>
                    </div>
                    <div v-if="activeObject=='auxiliary'">
                        <KCPassFieldInput v-model="applPassData.coupon.auxiliaryFields[activeObjectIndex]"
                            :title=auxiliaryHeader :maxValue="maxLabel(activeObjectIndex)" :hideKey=true></KCPassFieldInput>
                    </div>
                    <div v-if="activeObject=='secondary'">
                        <KCPassFieldInput v-model="applPassData.coupon.secondaryFields[activeObjectIndex]"
                        :hideLabel="isAddThisPassMode" :hideKey=true title="Secondary" :maxValue="maxLabel(activeObjectIndex)"></KCPassFieldInput>
                    </div>

                    <div v-if="activeObject=='barcode'">
                        <div class="prop-line prop-title">Barcode</div>
                        <div class="prop-line pb-1 pt-4">
                            <v-text-field hide-details  dense clearable v-model="applPassData.barcode.messageEncoding"
                                label="MessageEncoding">
                            </v-text-field>
                        </div>
                        <div class="prop-line py-0">
                            <!-- <v-text-field v-model="applPassData.barcode.format" label="Format">
                        </v-text-field> -->
                            <v-select @change="barcodeChange" label="Format" :items="calcBarcodeTypes"
                                v-model="applPassData.barcode.format"></v-select>
                        </div>
                        <template v-if="applPassData.barcode.format !='NoBardcode'">
                            <div ckass="prop-line py-0">
                                <!-- <v-checkbox :disabledx="applResouce.barcodeOther == true" class="mt-n1 ml-n1" hide-details dense
                                    v-model="applResouce.barcodeOther" label="From field"></v-checkbox> -->
                                <!-- <v-switch v-model="applResouce.barcodeOther" xcolor="success" hide-details>
                                    <template v-slot:label>
                                        <span v-if="applResouce.barcodeOther">From fields</span>
                                        <span v-else>Show options</span>
                                    </template>
                                </v-switch> -->
                                <div class="px-2">
                                <KCComputedInput :columnMode=true :readOnlyMode=effectiveReadonly  v-model=applResouce.templateData.barcodeOther :needName=false label="Barcode Message" :headers=Headers :sampleData=SampleData></KCComputedInput>
                                </div>
                            </div>
                            <div v-if="isAddThisPassMode == false" class="prop-line">
                                <div class="prop-line">
                                    <v-text-field clearable v-model="applPassData.barcode.message" label="Message">
                                    </v-text-field>
                                </div>
                            </div>
                        </template>
                        <div v-if="isAddThisPassMode == false" class="prop-line">
                            <v-text-field clearable v-model="applPassData.barcode.altText" label="Text below barcode">
                            </v-text-field>
                        </div>
                    </div>
                    <div v-if="activeObject=='backFields'">
                        <div class="mb-3 prop-line prop-title">BackFields</div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense
                                v-model="applPassData.coupon.backFields[activeObjectIndex].label" label="Label">
                            </v-text-field>
                        </div>
                        <div class="prop-line" style="height:300px;padding-bottom:10px;">
                            <v-textarea rows=14 row-height=16 outlined dense
                                v-model="applPassData.coupon.backFields[activeObjectIndex].value" label="Value">
                            </v-textarea>
                        </div>
                        <!-- <div class="prop-line">
                            <v-text-field hide-details clearable dense
                                v-model="applPassData.coupon.backFields[activeObjectIndex].key" label="Key">
                            </v-text-field>
                        </div> -->
                    </div>
                    <div class="add-this-pass" v-if="activeObject=='backFields-addThisPass'">
                        <div class="mb-3 prop-line prop-title">BackFields</div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.phone"
                                label="Phone"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.email"
                                label="Email"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.address"
                                label="Address"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.surrounding"
                                label="Surrounding"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.website_name"
                                label="Web"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.website"
                                label="URL"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link1_name"
                                label="Link1"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link1"
                                label="URL"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link2_name"
                                label="Link2"></v-text-field>
                        </div>
                        <div class="prop-line">
                            <v-text-field hide-details clearable dense v-model="applResouce.backFields.link2"
                                label="URL"></v-text-field>
                        </div>
                        <div class="prop-line" style="position: relative;">
                            <v-textarea  hide-details rows=14 row-height=16 outlined dense v-model="applResouce.backFields.more"
                                label="More">
                            </v-textarea>
                            <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" style="position: absolute;bottom: 7px;right: 12px;">
                                        <a href="https://mindfiresupport.freshdesk.com/support/solutions/articles/73000638878" target="blank">
                                            <v-icon class="ml-2">help</v-icon>
                                        </a>
                                    </div>
                                </template>
                                Learn how to build a clickable link here
                            </v-tooltip>
                        </div>
                        <div class="prop-line">
                            <v-btn @click="showHtmlClick()">{{htmlButtonLabel}}</v-btn>
                            <!-- <v-textarea rows=14 row-height=16 outlined dense v-model="applResouce.backFields.more"
                                label="More">
                            </v-textarea> -->
                        </div>
                    </div>

                </div>
            </div>
            </Transition>
            <div v-if="viewMode==false" class="mr-3">
                <div class="properties">
                    <div class="prop-line prop-title">Setting <span class="version" style="float: right;padding-top: 5px;font-size: x-small;">{{version}}</span></div>
                    <v-row v-if="hideCardType==false" class="prop-line mt-0 pt-0">
                        <v-col cols="12">
                            <v-select hide-details label="Card type" :items="cardTypes" item-text="name"
                                item-value="value" v-model="applResouce.templateData.cardType"></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="showWelcomeMessageOption" class="prop-line mt-0 pt-0">
                        <v-col style="align-content: center;" cols="6" class="py-1">Show </v-col>
                        <v-col cols="6" class="pa-0 py-1" style="width:300px;">
                        <v-switch style="margin-left:0px;" small class="mt-0 pa-0 custom-switch" v-model="applResouce.templateData.showWelcomeMessage" xcolor="success" hide-details>
                            <template v-slot:label>
                                <span style="font-size: smaller;" v-if="applResouce.templateData.showWelcomeMessage">Welcome Message</span>
                                <span style="font-size: smaller;" v-else>Most Recent Message</span>
                            </template>
                        </v-switch>
                        </v-col>
                    </v-row>
                    <v-row class="prop-line mt-0 pt-0">
                        <v-col cols="6" class="py-1">
                            <v-select class="select-apple-wallet" hide-details label="Wallet for Apple" :items=defaultAppleWallet item-text="name" item-value="value"
                            v-model="applResouce.templateData.defaultAppleWallet"></v-select>
                        </v-col>
                        <v-col cols="6" class="py-1">
                            <v-select class="select-apple-wallet" hide-details label="Wallet for Android" :items=defaultAndroidWallet item-text="name" item-value="value"
                            v-model="applResouce.templateData.defaultAndroidWallet"></v-select>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="prop-line mt-0 pt-0">
                        <v-col cols="12" class="pl-3 pt-0 pb-1" style="width:300px;">
                    </v-row> -->
                    <!-- <v-row class="prop-line mt-0 pt-0">
                        <v-col cols="12" class="pl-3 pt-0 pb-1" style="width:300px;">
                        <v-select class="select-apple-wallet" hide-details label="Wallet for Android" :items=defaultAndroidWallet item-text="name" item-value="value"
                            v-model="applResouce.templateData.defaultAndroidWallet"></v-select>
                        </v-col>
                    </v-row> -->

                    <v-row class="prop-line mt-0 pt-0" style="height:45px;">
                        <v-col cols="6" class="py-1">Text color</v-col>
                        <v-col cols="6" class="py-1">
                            <KCColorPicker v-model="applPassData.foregroundColor" :hideAlpha=true ouputFormat="rgb"
                                top="32px"></KCColorPicker>
                        </v-col>
                    </v-row>
                    <v-row v-if="singleColor==false" class="prop-line mt-0 pt-0">
                        <v-col cols="6" class="py-1">Label color</v-col>
                        <v-col cols="6" class="py-1">
                            <KCColorPicker v-model="applPassData.labelColor" :hideAlpha=true ouputFormat="rgb"
                                top="32px"></KCColorPicker>
                        </v-col>
                    </v-row>
                    <template v-if="cardTypeClass.includes('event-ticket') == false">
                        <v-row class="prop-line mt-0 pt-0">
                            <v-col cols="6" class="py-1">Background color</v-col>
                            <v-col cols="6" class="py-1">
                                <KCColorPicker v-model="applPassData.backgroundColor" :hideAlpha=true ouputFormat="rgb"
                                    top="32px"></KCColorPicker>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-else>
                        <v-row class="prop-line mt-0 pt-0">
                            <v-col cols="6" class="py-1"><label>Background</label></v-col>
                            <v-col cols="6" class="py-1">
                                <v-checkbox style="display:inline-block;" :disabled="applResouce.chkStripImage == true" class="bg-checkbox mt-n1 ml-n1" hide-details dense
                                    v-model="applResouce.chkBackgroundImage" @change="backgroundChange(['image',$event])" label="image"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="prop-line mt-0 pt-0">
                            <v-col cols="6" class="py-1"><label></label></v-col>
                            <v-col cols="6" class="py-1">
                                <v-checkbox v-if="applResouce.templateData.defaultAppleWallet!='Apple'" class="bg-checkbox mt-n1 ml-n1" hide-details dense
                                    v-model="applResouce.chkBackgroundVideo" @change="backgroundChange(['video',$event])" label="Use video"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="applResouce.chkStripImage == true" class="prop-line mt-0 pt-0">
                            <v-col style="padding-left:10px; font-size: x-small;font-style: italic;color: firebrick;" col="12" class="py-1">
                                Note: Background image is disabled when using a banner image.
                            </v-col>
                        </v-row>                            

                        <v-row v-if="applResouce.chkBackgroundImage==false && applResouce.chkBackgroundVideo==false" class="prop-line mt-0 pt-0">
                            <v-col cols="6" class="py-1">Color</v-col>
                            <v-col cols="6" class="py-1">
                                <KCColorPicker v-model="applPassData.backgroundColor" :hideAlpha=true ouputFormat="rgb"
                                    top="32px"></KCColorPicker>
                            </v-col>
                        </v-row>
                        <v-row v-if="applResouce.chkBackgroundImage" class="prop-line mt-0 pt-0">
                            <v-col cols="6" class="py-1">Image</v-col>
                            <v-col cols="6" class="py-1">
                                <div style="position:relative;" class="pa-1">
                                    <v-img  width="90" height="110" class="clickable"
                                        @click="showCropUI('backgroundImage')" :src=applResouce.backgroundImage></v-img>
                                        <!-- <v-btn style="position:absolute;" @click="showCropUI('backgroundImage',true)">edit</v-btn> -->
                                        <v-icon v-show="cropEditImage['backgroundImage']" @click="showCropUI('backgroundImage',true)"
                                        style="position: absolute;bottom: 8px;right: 40px;cursor: pointer;font-size: small;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>
                                </div>
                                <div v-if="applResouce.templateData.defaultAppleWallet!='Apple'">
                                     <v-checkbox class="mt-n1 ml-n1" hide-details dense
                                        v-model="applResouce.templateData.chkBlurBackgroundImage" label="Blurr"></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="applResouce.chkBackgroundVideo && applResouce.templateData.defaultAppleWallet!='Apple'" class="prop-line mt-0 pt-0">
                            <v-col cols="3" class="py-1">Video</v-col>
                            <v-col cols="9" class="mt-n1 py-1 px-1">
                                <v-text-field placeholder="youtube or vimeo url" hide-details clearable dense v-model="applResouce.backgroundVideoUrl"
                                label=""></v-text-field>
                                <v-img v-if="applResouce.backgroundVideoThumbnailUrl" height="110" class="ma-2"
                                        :src=applResouce.backgroundVideoThumbnailUrl></v-img>

                            </v-col>                                
                                <!-- <div style="position:relative;" class="pa-1">
                                    <v-img  width="90" height="110" class="clickable"
                                        @click="showCropUI('backgroundImage')" :src=applResouce.backgroundImage></v-img>
                                        <v-icon v-show="cropEditImage['backgroundImage']" @click="showCropUI('backgroundImage',true)"
                                        style="position: absolute;bottom: 8px;right: 40px;cursor: pointer;font-size: small;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>
                                </div>
                                <div>
                                     <v-checkbox class="mt-n1 ml-n1" hide-details dense
                                        v-model="applResouce.templateData.chkBlurBackgroundImage" label="Blur"></v-checkbox>
                                </div> -->
                            <!-- </v-col> -->
                        </v-row>                        
                    </template>
                    <template v-if="isAddThisPassMode || isMindFireMode">
                        <v-row class="prop-line mt-0 pt-2">
                            <v-col cols="12" class="py-1">
                            <v-text-field hide-details clearable dense v-model="applResouce.templateData.greetingMessage"
                                label="Message when first scanning"></v-text-field>
                            </v-col>
                        </v-row>
                        <!-- <v-row class="prop-line mt-1 pt-2">
                            <v-col cols="12" class="py-1">
                                <v-select hide-details label="Time zone" v-model="applResouce.templateData.timeZone" :items="timezones" placeholder="Timezone" dense style="height: 32px;"></v-select>
                            </v-col>
                        </v-row> -->
                    </template>
                    <template v-if="isMindFireMode">
                        <v-row class="prop-line mt-0 pt-2">
                            <v-col cols="12" class="py-1">
                            <v-text-field hide-details clearable dense v-model="applResouce.templateData.redirectURL"
                                label="Redirect to"></v-text-field>

                            </v-col>
                        </v-row>
                    </template>
                </div>
            </div>
            <!-- KKK -->
            <div v-if="pushMessageMode" class="mr-3">
                <div class="properties">
                    <div class="prop-line prop-title">Send message <span class="version" style="float: right;padding-top: 5px;font-size: x-small;">{{version}}</span></div>
                    <v-row class="prop-line mt-2 pt-0">
                        <v-col col="12">
                        <v-text-field clearable dense counter :maxlength=45 v-model="applPassData.coupon.primaryFields[0].value"
                                label="Line 1"></v-text-field>
                        </v-col>
                        <div v-if="applPassData.coupon.primaryFields[0].value =='' || applPassData.coupon.primaryFields[0].value ==null" style="padding: 0px 15px; font-size: small;font-style: italic;color: firebrick;">
                            Note: Send message require line 1 value.
                        </div>                                
                    </v-row>
                    <v-row class="prop-line mt-0 pt-0">
                        <v-col col="12">
                        <v-text-field clearable dense counter :maxlength=45 v-model="applPassData.coupon.secondaryFields[0].value"
                                label="Line 2"></v-text-field>
                        </v-col>                                
                    </v-row>
                    <v-row class="prop-line mt-0 pt-0">
                        <v-col col="12">
                        <v-text-field clearable dense counter :maxlength=45 v-model="applPassData.coupon.secondaryFields[1].value"
                                label="Line 3"></v-text-field>
                        </v-col>                                
                    </v-row>
                    <!-- xxx {{ applResouce.chkStripImage }} -->
                    <v-row v-if="applResouce.chkStripImage == false" class="prop-line mt-0 pt-0">
                        <v-col col="12" class="py-1">
                            <v-checkbox style="display:inline-block;width:50%;" hide-details dense v-model="applResouce.chkThumbnail" label="Thumbnail">
                            </v-checkbox>
                            <v-checkbox v-if="isCheckThumbNail"  style="display:inline-block;width:50%;" hide-details dense v-model="applResouce.useLogoAsThumbnail" label="Use Logo as Thumbnail">
                            </v-checkbox>
                        </v-col>                                
                    </v-row>
                    
                    <v-row v-if="isCheckThumbNail"   class="my-0 py-0 ">
                        <v-col col="12" class="my-0 py-0 ">
                            <div class="ma-0 pa-0 "
                                style="border: solid 1px #c3bcbc;border-radius: 10px;">
                                <div v-if="applResouce.useLogoAsThumbnail==false" class="prop-line">
                                    <div  style="position:relative;">
                                <v-img class="clickable" contain width="40%" style="margin:auto;" @click="showCropUI('thumbnail')"
                                    :src=applResouce.thumbnail></v-img>
                                    <v-icon v-show="cropEditImage['thumbnail']" @click="showCropUI('thumbnail',true)"
                                        style="position: absolute;bottom: 2px;right: 70px;cursor: pointer;font-size: 18px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    
                                    </div>                                    
                                </div>                                    
                                <div v-else class="prop-line">
                                    <div  style="position:relative;">
                                <v-img class="clickable" contain width="40%" style="margin:auto;" @click="showCropUI('logo')"
                                    :src=applResouce.logo></v-img>
                                    <v-icon v-show="cropEditImage['logo']" @click="showCropUI('logo',true)"
                                        style="position: absolute;bottom: 2px;right: 70px;cursor: pointer;font-size: 18px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    
                                    </div>                                    
                                </div>                                    
                            </div>     
                        </v-col>
                    </v-row>
                    <!-- <v-row v-if="applResouce.useLogoAsThumbnail"   class="my-0 py-0 ">
                        <v-col col="12" class="my-0 py-0 ">
                            <div class="ma-0 pa-0 "
                                style="border: solid 1px #c3bcbc;border-radius: 10px;">
                                <div class="prop-line">
                                    <div  style="position:relative;">
                                <v-img class="clickable" contain width="40%" style="margin:auto;" @click="showCropUI('logo')"
                                    :src=applResouce.logo></v-img>
                                    <v-icon v-show="cropEditImage['logo']" @click="showCropUI('logo',true)"
                                        style="position: absolute;bottom: 2px;right: 70px;cursor: pointer;font-size: 18px;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>                                    
                                    </div>                                    
                                </div>                                    
                            </div>     
                        </v-col>
                    </v-row> -->

                </div>
                <!-- <button @click="cropUI.show = !cropUI.show;">Crop</button>
                <img :src="imgDataUrl"> -->
            </div>
        </v-row>
        <v-row v-if="viewMode==false">
            <v-col cols="12">
                <div class="pb-3">
                    <template v-if="debugMode && canSave() == true">
                        <v-row>
                            <v-col cols="9">
                            <v-btn @click="saveAsJson()">Save As Json</v-btn>
                            <v-btn v-show="isUpdateMode==false" @click="createPassClick()">Create Pass</v-btn>
                            <v-btn v-show="isUpdateMode==true" @click="createPassClick()">Update Pass</v-btn>
                            <!-- <v-text-field v-if="isAddThisPassMode==false && isUpdateMode==true" class="pl-2" style="display:inline-flex;width:500px;" hide-details clearable dense v-model="applResouce.templateData.pushMessage"
                                label="Push Message"></v-text-field> -->
                            </v-col>
                        </v-row>
                    <div class="pt-1" v-if="isAddThisPassMode">
                        <v-btn class="mr-3" @click="createAssThisPassClick()">Create/update Pass (AddThisPass)</v-btn>
                        <span style="display:inline-block;width:120px;"><v-select v-model="addThisPassMode" :items="['passinfo','pushmsg']"></v-select></span>
                    </div>
                    </template>                    
                </div>
                <div v-if="passQRCodeImage">
                    <div><img style="width:300px;" :src="passQRCodeImage" /></div>
                    <div><a :href="passUrl">Add this pass</a></div>
                </div>
            </v-col>
        </v-row>
        </v-form>
        <KCImageCropper ref="cropper" :key="cropUI.show" :width=cropUI.width :maxWidth=cropUI.maxWidth :maxHeight=cropUI.maxHeight
            :height=cropUI.height @crop="onCrop" v-model="cropUI.show" :src="initialImage"></KCImageCropper>
        <v-file-input ref="fileInput" style="display: none" @change="loadImageFile" />
        <!-- <button @click="$refs.file.click()">open file dialog</button> -->
        <v-dialog  v-model="showLoadDialog" width="760">
              <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="415" width="100%">
                  <v-row>
                    <v-col cols="12">
                        <label class="text-h6">My Passes</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols=colSize>
                        <v-data-table v-model="selected" :loading="passDataLoading" dense :hide-default-footer="false" :headers="passDataTable.headers" :items="passDataTable.items" :items-per-page="5"
                        :single-select="true" show-select
                        sort-by="lastModified"
                        sort-desc
                        class="elevation-1">
                            <template v-slot:[`item.updatedAt`]="props">
                                <div>{{ fromNow(props.item.updatedAt)}}</div>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col v-show="selected.length > 0" cols=4 style="padding-top:0px;padding=left:25px;max-height:323px;">
                        <template >
                            <KCCardBuilderView :useMongoDB=useMongoDB style="transform-origin: top left;transform: scale(0.58);" ref="cardBuilderPreview" :loadPassCommand=loadPassCommand templateName="" :editMode=editMode :readOnlyMode=readOnlyMode @canSave="(n)=> shouldShowWalletPassSaveButton = n" :headers="headers" :sampleData="sampleData" :hideCardType=true :debugMode=false :autoLoadDefault=true :viewMode=true :lightMode=true></KCCardBuilderView>
                        </template>
                    </v-col>
                  </v-row>
                  <div style="position: absolute;bottom: 15px;width: 95%;margin-right:20px;">
                     <!-- <v-btn style="float:right;" @click="showLoadDialog=false">Close</v-btn> -->
                     <v-btn style="float:right;" @click="loadSelect()">Select</v-btn>
                  </div>
              </v-sheet>
        </v-dialog>
        <v-dialog v-model="showHtmlEditorDialog" width="900">
              <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="550" width="100%">
                  <v-row>
                    <v-col cols="12">
                        <label>HTML Editor</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                        <editor v-model="passHtml" @init="editorInit" lang="html" theme="chrome" width="560" height="420"></editor>
                    </v-col>
                    <v-col cols="4">
                        <div style="overflow:hidden">
                            <div style="height:300px;"> 
                                <iframe width="100%" height="300px" class="scaled-frame" :srcdoc="passHtml" allowfullscreen></iframe>
                            </div>
                        </div>
                    </v-col>
                  </v-row>
                  <div style="position: absolute;bottom: 15px;width: 95%;">
                     <v-btn style="float:right;" @click="showHtmlEditorDialog=false">Close</v-btn>
                     <v-btn style="float:right;margin-right:10px;" @click="saveHtmlClick()">Save</v-btn>
                  </div>
              </v-sheet>
        </v-dialog>  
        <v-dialog persistent v-model="showPreference" width="900">
            <v-sheet style="position:relative;" class="rounded-lg mx-auto pa-5" elevation="12" height="550" width="100%">
                <JWAlertPreference ref="preference"></JWAlertPreference>
                <div style="position: absolute;bottom: 15px;width: 95%;">
                    <v-btn style="float:right;" @click="showPreference=false">Close</v-btn>
                    <!-- <v-btn style="float:right;margin-right:10px;" @click="saveHtmlClick()">Save</v-btn> -->
                </div>
            </v-sheet>
        </v-dialog>
    </v-container>
</template>
<style>


@import '../assets/styles/cardViewer.css';
.scaled-frame {
    border:none;
    height: 1100px;
    zoom: 0.35;
    -moz-transform: scale(0.35);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.35);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.35);
    -webkit-transform-origin: 0 0;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .scaled-frame {
        zoom: 1;
    }
}
.custom-switch .v-input--selection-controls__input div {
  color:#1976d2;
}

.custom-switch .v-input--switch__thumb.theme--light {
  color: #1976d2;
}

.custom-switch .v-input--switch__track.theme--light {
  color: #1976d2;
}

div.bg-checkbox label.v-label {
    left:-8px !important; 
}
.select-apple-wallet .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.select-apple-wallet .v-select__selection {
  font-size: 13px;
}
</style>
<script>
// passbook tutorial https://www.kodeco.com/2853-beginning-passbook-in-ios-6-part-2-2
// import contenteditable from 'vue-contenteditable';
// import 'babel-polyfill'; 
import axios from "axios";
import utils from '../services/KCUtils.js'
import csvUtils from "../services/KCCSVUtils.js";
import KCColorPicker from "../components/KCColorPicker.vue";
import KCPassFieldInput from "../components/KCPassFieldInput.vue";
import KCImageCropper from "../components/KCImageCropper.vue";
import KCComputedInput from "../components/KCComputedInput.vue";
import KCQRCodeView from "../components/KCQRCodeView.vue";
//import KCSearchableSelect from "../components/KCSearchableSelect.vue";
import defaultPass from '../assets/json/cardDefault.json';
import JWAlertPreference from "../components/JWAlertPreference.vue";
// import vuetify from '../plugins/vuetify'
//var Jimp = require('jimp');
import kcMixins from "../services/KCMixins.js";
export default {
    name: "KCCardBuilderView",
    mixins: [kcMixins],   
    // vuetify,
    // directives: {
    //     ResizeText
    // },
    components: {
        // contenteditable
        KCColorPicker,
        KCPassFieldInput,
        // myUpload,
        KCImageCropper,
        KCComputedInput,
        KCQRCodeView,
        JWAlertPreference,
        editor: require('vue2-ace-editor'),
        //KCSearchableSelect,
        // VueCropper
    },
    props: {
        // value: null,
        serverMode: {
            default: "pass",
        },
        defaultCardType: {
            default: "event-ticket-add-this-pass",
            //default: "mindfire",
        },
        hideCardType: {
            default: false,
        },
        debugMode: {
            default: false,
        },
        headers: {
            default(){
                return [];    
            }
        },
        sampleData: {
            default(){
                return [];    
            }
        },
        autoLoadDefault: {
            default: true,
        },
        readOnlyMode: {
            default:false,
        },
        editMode:{
            default:false,
        },
        viewMode: {
            default:false,
        },
        lightMode: {
            default: false,
        },
        backgroundColor: {
            default:"white",
        },
        templateName: {
            default: "New Pass " + utils.UTCDateTime(),
        },
        requireMessageInput: {
            default: false,
        },
        requireLinkInput: {
            default: false,
        },
        loadPassCommand: {
            default(){
                return null;    
            }
        },
        pushMessageMode: {
            default: false,
        },
        cacheTime: {
            default: 30*1000,
        },
        useMongoDB: {
            default: false,
        },
        loadListServiceHeader: {
            default: true,
        },
        loadItems: {
            default(){
                return null;    
            }
        },
        showQRCode: {
            defualt: false,
        },
        qrcodeUrl: {
            default: null,
        },
        hideAuxilary: {
            default: true,
        },
        showWelcomeMessageOption: {
            default: false,
        },
        singleColor: {
            default: true,
        }
    },
    data() {
        var config = utils.getServerConfig(this.serverMode);
        console.log("data",process.env.VUE_APP_WEB_COMPONENT);
        console.log("data",defaultPass);
        console.log("config",config);
        //var davinciUrl = this.$store.getters.deployment.dvURL;
        let server = "prod";
        //let lsEndPoint = this.getListServiceEndPoint();
        // if(davinciUrl.indexOf("davincistage")>=0){
        //     server = "stage";
        //     lsEndPoint = "https://services-stg.mdl.io";
        // }       
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */                
            version: "v1.02",
            server: server,
            menu: false,
            isFormValid:true,
            isPassValid:true,
            root: null,
            endPoint: config.endPoints.mindfireOne + `/api/card/${server}`,
            //lsEndPoint: lsEndPoint,
            qrcodeEndPoint: config.endPoints.qrcode,
            csvProcessorEndPoint: utils.getServerConfig(utils.getServerMode("mindfireOne")).endPoints.mindfireOne + "/api/csvprocessor",
            showingFront: true,
            //cardType: "event-ticket-add-this-pass", //"coupon",
            cardTypes: [
                { name: "Coupon", value: "coupon" },
                { name: "MindFire", value: "mindfire" },
                { name: "AddThisPass", value: "event-ticket-add-this-pass" },

            ],
            Headers: this.headers,
            SampleData: this.sampleData,
            //displayMode: "add-this-passX",
            cropUI: {
                show: false,
                mode: "",
                width: 200,
                height: 200,
                maxWidth: null,
            },
            initialImage: null,
            tabImageSize: {
                "coupon": {
                    // width: 375,     //x3 
                    // height: 89,  //144
                    width: 330,     //x3 
                    height: 123,  //144

                },
                "logo": {
                    // width: 150,     //480x150
                    // height: 150,
                    // maxWidth: 400, //300
                    width: 125,
                    height: 125,
                    maxWidth: 400,
                },
                "backgroundImage": {
                    width: 180,     //180x220 
                    height: 220,
                },
                "thumbnail":{
                    width: 200,     //480x150
                    height: 200,
                    maxWidth: 300,
                    maxHeight: 300,
                }

            },
            barcodeTypes: {
                "NoBardcode": "",
                "PKBarcodeFormatQR": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode2.png" /*require("../assets/barcode2.png")*/,
                "PKBarcodeFormatPDF417": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode1.png"/*require("../assets/barcode1.png")*/,
                "PKBarcodeFormatCode128": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode4.png"/*require("../assets/barcode4.png")*/,
                "PKBarcodeFormatAztec": "https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/barcode3.png"/*require("../assets/barcode3.png")*/,
            },
            applResouce: defaultPass.resource,
            applPassData: defaultPass.passData,
            activeObject: null,
            activeObjectIndex: null,
            localStripFileInput: null,
            logoWidth: null,
            logoHeight: null,
            thumbnailWidth: null,
            thumbnailHieght: null,
            passUrl: null,
            passQRCodeImage: null,
            fitTextCanvasContext: null,
            backdoor: 0,
            moreFields: [
                {
                    "key": "expires1",
                    "label": "EXPIRES",
                    "value": "1234567890"
                },
                {
                    "key": "expires2",
                    "label": "EXPIRES",
                    "value": "1234567890"
                },
                // {
                //     "key": "ref",
                //     "label": "Booking Reference",
                //     "value": "LAX-123456789"
                // }                
            ],
            defaultImage: {

            },
            currentPassID: null,
            currentPass: null,
            addThisPassMode: "passinfo",
            cardLoading: false,
            showLoadDialog: false,
            showHtmlEditorDialog: false,
            passHtml: "",
            passDataLoading: false,
            
            passDataTable: {
                headers: [
                    // { text: "ID", value: "id", sortable: true},
                    { text: "Name", value: "name", sortable: true,/* width:"350px"*/},
                    // { text: "LastModified", value: "lastModified", sortable: true},
                    { text: "LastModified", value: "updatedAt", sortable: true,width: '150px' },
                    // { text: "Action", value: "action", sortable: false, align:"right"},
                ],
                items: [

                ],
            },
            qrcodeColumnRule: [
                value => {
                    return true;
                }
            ],
            showOptions: false,
            cropInited: false,
            cropEditImage: {

            },
            varValueColor: "rgb(26,26,22)",
            varLabelColor: "rgba(0,0,0,1)",
            passCache: {},
            loadByIDWaiting: false,
            selected: [],
            qrcodeMenu: false,
            defaultAppleWallet: [
                {name:"Apple Wallet",value:"Apple"},{name:"Mobile Wallet",value:"Mobile"},{name:"Let User Decide",value:"User"}
            ],
            defaultAndroidWallet: [
                {name:"Google Wallet",value:"Android"},{name:"Mobile Wallet",value:"Mobile"},{name:"Let User Decide",value:"User"}
            ],
            showPreference: false,
            timezones: [{"text":"Hawaii Time","value":"Pacific/Honolulu","atpnum":"-6"},{"text":"Alaska Time","value":"America/Anchorage","atpnum":"-4"},{"text":"Pacific Time","value":"America/Los_Angeles","atpnum":"-3"},{"text":"Mountain Time","value":"America/Denver","atpnum":"-2"},{"text":"Central Time","value":"America/Chicago","atpnum":"-1"},{"text":"Eastern Time","value":"America/New_York","atpnum":"0"},{"text":"SE Asia Time","value":"Asia/Bangkok","atpnum":"11"}],
            localFileInput: null,
        };
    },
    computed: {
        colSize: function(){
            return this.selected.length >0 || this.previewLeaving ? 8:12;
        },
        htmlButtonLabel(){
            if(this.applResouce.backFields.html.trim()==""){
                return "Add HTML";
            }else{
                return "Edit HTML";
            }
        },
        effectiveReadonly(){
            if(this.editMode) return false;
            return this.readOnlyMode;
        },
        // moreFields(){
        //     var ret = [];
        //     ret = [... this.applPassData.coupon.secondaryFields, ... this.applPassData.coupon.auxiliaryFields];
        //     return ret;
        // },
        mainStyle() {
            var ret = {
                height: "100%", 
                width: "100%",
                background: this.backgroundColor,
                "--outline-color": "yellow",
                "--value-color": this.varValueColor,
                "--label-color": this.varLabelColor, //"rgba(0,0,0,1)",
                "--pass-height": "460px",
                "--main-bg-color": this.backgroundColor,                
            }
            if(this.viewMode){
                delete ret.height;
            }
            return ret;
        },
        loadingStyle(){
            return {opacity:this.applResouce && this.defaultImage.logo && this.loadByIDWaiting == false?1:0};
        },
        auxiliaryHeader(){
            return this.isMindFireMode?"Send message":"Auxiliary";
        },
        barcodeImage() {
            return this.barcodeTypes[this.applPassData.barcode.format];
        },
        calcBarcodeTypes() {
            var ret = [];
            for (var b in this.barcodeTypes) {
                if(this.isAddThisPassMode){
                    // "PKBarcodeFormatPDF417": require("../assets/barcode1.png"),
                // "PKBarcodeFormatCode128": require("../assets/barcode4.png"),
                // "PKBarcodeFormatAztec": require("../assets/barcode3.png"),
                    if((b == "PKBarcodeFormatPDF417") ||(b == "PKBarcodeFormatAztec")){
                        continue;
                    }
                }
                ret.push(b);
            }
            return ret;
        },
        applStripImage() {   // return object camelStyle
            // if (this.cardTypeClass.includes("add-this-pass")) {
            //     return { "background-image": "none" }
            // }
            if ((this.applResouce.chkStripImage == true) && this.applResouce.stripImage != "") {
                return { "background-image": `url(${this.applResouce.stripImage})` }
            } else {
                return { "background-image": "none" }
            }
        },
        passColorStyle() {
            if (this.cardTypeClass.includes("event-ticket")) {
                if (this.applResouce.chkBackgroundImage == true) {
                    return { "background-color": "transparent" }
                }
                if (this.applResouce.chkBackgroundVideo == true) {
                    return { "background-color": "transparent" }
                }
            }
            return { "background-color": this.applPassData.backgroundColor }
        },
        passStyle() {    // back-ground image only for event ticket 
            this.backdoor;  // reference backdoor to force update;
            if (this.cardTypeClass.includes("event-ticket")) {
                if ((this.applResouce.chkBackgroundImage == true) && this.applResouce.backgroundImage) {
                    return { "background-image": `url(${this.applResouce.backgroundImage})` }
                }
                if ((this.applResouce.chkBackgroundVideo == true) && this.applResouce.backgroundVideoThumbnailUrl) {
                    return { 
                        "background-image": `url(${this.applResouce.backgroundVideoThumbnailUrl})`,
                        // "background-size": "auto",
                        "background-position": "center",
                    }
                }

            }
            return { "background-image": "none" }
        },
        logoStyle() {
            if (this.logoWidth == null) {
                return null;
            } else {
                console.log("logoStyle",this.logoWidth,this.logoHeight);
                return {
                    "width": Math.min(112,Math.floor(42 * this.logoWidth / this.logoHeight)) + "px",
                    // "min-width": Math.floor(42) + "px",
                }
            }
        },
        thumbnailStyle(){
            if (this.thumbnailWidth == null) {
                return null;
            } else {
                return {
                    "width": Math.floor(42 * this.thumbnailWidth / this.thumbnailHieght) + "px",
                    // "min-width": 42 + "px",
                }
            }
        },
        logoTextStyle() {
            if (this.cardTypeClass.includes("event-ticket")) {
                return { "color": "var(--label-color)" }
            } else {
                return { "color": "var(--value-color)" }
            }
        },
        cardTypeClass() {
            var viewMode = this.viewMode?["view-mode"]:[];
            switch (this.applResouce.templateData.cardType) {
                case "coupon": return ["coupon",... viewMode];
                case "mindfire": return ["mindfire",... viewMode];
                case "event-ticket": return ["event-ticket",... viewMode];
                case "event-ticket-add-this-pass": return ["event-ticket", "add-this-pass",... viewMode];
            }
            return [... viewMode];
        },
        isAddThisPassMode(){
            return this.applResouce.templateData.cardType == "event-ticket-add-this-pass";
        },
        isMindFireMode(){
            return this.applResouce.templateData.cardType == "mindfire";
        },
        isUpdateMode(){
            return this.currentPassID!=null;
        },
        renderMoreField(){
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.applResouce.backFields.more, 'text/html');    
            //find A tag and make it target to blank
            //var els = doc.getElementsByTagName('a');
            //console.log("renderMoreField",els);
            var els = doc.getElementsByTagName("BODY")[0].getElementsByTagName("*");    
            var ret = null;
            for(var i =0;i<els.length;i++){
                if(els[i].tagName == 'A'){
                    els[i].setAttribute("target","_blank");
                }else{
                    //HTML tag ${els[i].tagName} not supported.<br>
                    ret = `<span style="color:red;">Warning! Only html tag &lt;a&gt; is supported</span>`
                    break;
                }
            }            
            if(ret==null) ret = doc.getElementsByTagName("BODY")[0].innerHTML;
            console.log("renderMoreField",ret);
            return ret;
        },  
        isCheckThumbNail(){
            return this.applResouce.chkThumbnail;
        }       
    },
    methods: {
        qrcodeClick: async function(param){
            console.log("qrcodeClick",param);
            switch(param){
                case "open": this.qrcodeMenu = true; break;
                case "copy": 
                    await navigator.clipboard.writeText(this.qrcodeUrl);
                    console.log("done");
                    break;
                case "download":
                    this.$refs.qrcodeView.saveImage();
                    break;
            }
        },

        backgroundChange(evt){
            console.log("backgroundChange",evt);
            if(evt[1]){
                if(evt[0]=="image"){
                    this.applResouce.chkBackgroundVideo = false;
                    this.applResouce.chkBackgroundImage = true;
                }else{
                    this.applResouce.chkBackgroundVideo = true;
                    this.applResouce.chkBackgroundImage = false;
                }
            }
        },
        fromNow(date){
            return utils.fromNow(date);
        },
        maxTextLength(){
            if(this.isAddThisPassMode){
                return 20;
            }else{
                return 15;
            }
        },
        objClick(type, index, event) {
            if(this.viewMode == true){
                return;
            }
            console.log("objClick", type,this.activeObject);
            //this.activeObjectIndex = index;
            var actObjName = `${this.activeObject}`;
            if (this.activeObjectIndex != null) {
                actObjName = `${this.activeObject}-${this.activeObjectIndex}`;
            }
            var clickObjName = `${type}`;
            if (index != null) {
                clickObjName = `${type}-${index}`;
            }

            if (actObjName == clickObjName) {
                //if(this.isAddThisPassMode == false){
                    this.activeObject = null;
                    this.activeObjectIndex = null;
                //}
            } else {
                this.activeObject = type;
                this.activeObjectIndex = index;
            }

        },
        stripeImageChange(evt) {
            console.log("stripeImageChange", evt);
            if (evt == null) {
                this.localStripFileInput = null;
            } else {
                if (evt.type.split("/")[0] != "image") {
                    alert("Not a image file");
                    return
                }
            }
        },
        flipPass() {
            console.log("flipPass");
            this.showingFront = !this.showingFront;
            //if(this.showingFront){
                //this.objClick(this.activeObject, null);
            this.activeObject = null;
            if(this.showingFront == false){
                if(this.isAddThisPassMode){
                    this.objClick('backFields-addThisPass',null);
                }
            }
            //}
            // if (this.showingFront == false) {
                 
            //     this.objClick("backFields-addThisPass", null);
            // } else {
            //     if (this.activeObject == "backFields-addThisPass") {
            //         this.objClick("backFields-addThisPass", null);
            //     }
            // }
        },

        cropUploadFail(param) {
            console.log("cropUploadFail", param);
        },
        cropUploadSuccess(param) {
            console.log("cropUploadSuccess", param);
        },
        loadImageFile(e) {
            if (e) {
                console.log(e);
                // Encode the file using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Use a regex to remove data url part
                    const base64String = reader.result
                    // .replace('data:', '')
                    // .replace(/^.+,/, '');

                    //console.log(base64String);
                    this.initialImage = base64String;
                    // save 
                    this.cropEditImage[this.cropUI.mode] = base64String;
                    this.cropUI.show = true;
                    //clear file Input 
                    this.$refs.fileInput.$refs.input.value = null;
                    // Logs wL2dvYWwgbW9yZ...
                };
                reader.readAsDataURL(e);
            }
        },
        // _showCropUI(){

        // },
        showCropUI(mode,flgEdit = false) {
            // this.$refs.file.click();
            // this.$refs.cropper.chooseFile();
            console.log("showCropUI", mode,this.cropInited);
            this.cropUI.mode = mode;
            this.cropUI.flgEdit = flgEdit;
            switch (mode) {
                case "stripImage": {
                    this.cropUI.width = this.tabImageSize.coupon.width;
                    this.cropUI.height = this.tabImageSize.coupon.height;
                    this.cropUI.maxWidth = null;
                    this.cropUI.maxHeight = null;
                    break;
                }
                case "logo": {
                    this.cropUI.width = this.tabImageSize.logo.width;
                    this.cropUI.maxWidth = this.tabImageSize.logo.maxWidth;
                    this.cropUI.height = this.tabImageSize.logo.height;
                    this.cropUI.maxHeight = null;
                    break;
                }
                case "thumbnail": {
                    this.cropUI.width = this.tabImageSize.thumbnail.width;
                    this.cropUI.maxWidth = this.tabImageSize.thumbnail.maxWidth;
                    this.cropUI.height = this.tabImageSize.thumbnail.height;
                    this.cropUI.maxHeight = this.tabImageSize.thumbnail.maxHeight;
                    break;
                }
                case "backgroundImage": {
                    this.cropUI.width = this.tabImageSize.backgroundImage.width;
                    this.cropUI.maxWidth = this.tabImageSize.backgroundImage.maxWidth;
                    this.cropUI.height = this.tabImageSize.backgroundImage.height;
                    this.cropUI.maxHeight = null;
                    break;
                }

            }
            console.log("showCropUI",this.cropUI,"flgEdit",flgEdit);
            if(flgEdit == false){
                // brower new image
                this.$refs.fileInput.$refs.input.click()
            }else{
                console.log(this.cropEditImage);
                if(this.cropEditImage[this.cropUI.mode] != undefined ){
                    this.initialImage = this.cropEditImage[this.cropUI.mode];
                }
                this.cropUI.show = true;
            }
            return;

        },
        onCrop(param) {
            var dataUrl = param.dataUrl;
            // var width = param.width;
            this.cropInited = true;
            console.log("onCrop", this.cropUI.mode, param);
            switch (this.cropUI.mode) {
                case "stripImage": {
                    this.applResouce.stripImage = dataUrl;
                    break;
                }
                case "logo": {
                    this.applResouce.logo = dataUrl;
                    this.logoWidth = param.width;
                    this.logogHeight = param.height;
                    break;
                }
                case "thumbnail": {
                    this.applResouce.thumbnail = dataUrl;
                    this.thumbnailWidth = param.width;
                    this.thumbnailHeight = param.height;
                    break;
                }
                case "backgroundImage": {
                    this.applResouce.backgroundImage = dataUrl;
                    this.backdoor++;
                    // this.logoWidth = width;
                    break;
                }

            }
            this.$forceUpdate();
        },
        barcodeChange(value) {
            console.log("barcodeChange", value)
        },
        getTextValue(refName) {
            switch (refName) {
                case "backContact": return `-P:${this.applResouce.backFields.phone}\n-E:${this.applResouce.backFields.email}`;
                case "backAddress": return `-P:${this.applResouce.backFields.address}\n(${this.applResouce.backFields.surrounding})`;
                case "backLinks": return `- ${this.applResouce.backFields.website_name}\n- ${this.applResouce.backFields.link1_name}\n- ${this.applResouce.backFields.link2_name}`;
                case "backMore": return this.applResouce.backFields.more;
            }
        },
        readBlobAsync(blob) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            })
        },
        async getImageAsDataURL(url, callback) {
            try {
                //console.log("baseUrl:", process.env.BASE_URL);
                const result = await fetch(url);
                const blob = await result.blob();
                const readResult = await this.readBlobAsync(blob);
                return readResult;
                // const reader = new FileReader();
                // reader.readAsDataURL(blob);
                // reader.addEventListener("load", () => {
                //     // convert image file to base64 string
                //     if (callback) {
                //         callback(reader.result);
                //     }
                // }, false);
                // console.log(result);
            } catch (error) {
                console.log(error);
            }
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        renderString(value,header,data){
            if(value){
                var subCommand = csvUtils.createSubsitiueCommand(value,header);
                //console.log(subCommand);
                return csvUtils.subsituteValue(data,subCommand);
            }else{
                return "";
            }
        },
        renderFields(fieldName,fieldData,header,data){
            console.log("renderField",fieldName,fieldData);
            for(let x in fieldData){
                let field = fieldData[x];
                if("value" in field){
                    field.value = this.renderString(field.value,header,data);
                    // if(field.value.indexOf("##")>=0){
                    //     console.log("found",field.value);
                    //     field.value = "[" + field.value + "]";
                    // }
                }
            }
            console.log("renderField done",fieldName,fieldData);
        },
        renderCardData(cardData,header,data){
            let ret = JSON.parse(JSON.stringify(cardData));
            for(let x in ret.passData.coupon){
                this.renderFields(x,ret.passData.coupon[x],header,data);
            }
            ret.passData.logoText = this.renderString(ret.passData.logoText,header,data);
            ret.resource.templateData.greetingMessage = this.renderString(ret.resource.templateData.greetingMessage,header,data);
            ret.resource.templateData.redirectURL = this.renderString(ret.resource.templateData.redirectURL,header,data);
            return ret;
        },
        setCardData(_cardData) {
            let cardData = this.clone(_cardData);
            this.applResouce = cardData.resource;
            console.log("setCardData", cardData.resource)
            console.log("setCardData", cardData.passData)
            //check ticket type
            if ("eventTicket" in cardData.passData) {
                cardData.passData.coupon = cardData.passData.eventTicket;
                delete cardData.passData.eventTicket;
            }
            // render personalize
            if(this.viewMode == true){
                if(this.SampleData.length > 0){
                    cardData = this.renderCardData(cardData,this.Headers,this.SampleData[0]);
                }
            }

            this.applPassData = cardData.passData;
            
            switch (this.applResouce.templateData.cardType) {
                case "coupon": {
                    //create morefield from secondary
                    this.moreFields = [... this.applPassData.coupon.secondaryFields];
                    break;
                }
                case "mindfire": {
                    
                    break;
                }
            }
            return cardData;
        },
        hasATag(data){
            const regex = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/gmi;
            let m = regex.exec(data);
            console.log("hasATag",data, m != null)
            return m != null;
        },
        getCardData() {
            var cardData = {
                resource: this.clone(this.applResouce),
                passData: this.clone(this.applPassData),
            }
            // correct image 

            switch (this.applResouce.templateData.cardType) {
                case "coupon": {
                    // rebuild secondary field
                    cardData.passData.coupon.secondaryFields = [... this.moreFields];
                    cardData.passData.coupon.auxiliaryFields = [];
                    // remove background image
                    delete cardData.resource.backgroundImage;
                    // remove thumbnail image
                    delete cardData.resource.thumbnail;
                    break;
                }
                case "event-ticket": {
                    // cardData.passData.coupon.secondaryFields = [];
                    // cardData.passData.coupon.auxiliaryFields = [];
                    break;
                }
                // case "mindfire":{
                //     // remove header 
                //     cardData.passData.coupon.headerFields = [];
                //     // make sure we have 1 secondardy fields
                //     delete cardData.passData.coupon.secondaryFields[1];
                // }
            }
            // rebuild back field
            var bf = cardData.passData.coupon.backFields;
            if(this.isAddThisPassMode){
                bf.length = 0;
                bf.push({
                    "key": "contact",
                    "label": "CONTACT US:",
                    "value": this.getTextValue("backContact"),
                })
                bf.push({
                    "key": "address",
                    "label": "LOCATE US:",
                    "value": this.getTextValue("backAddress"),
                })
                bf.push({
                    "key": "links",
                    "label": "VISIT US:",
                    "value": this.getTextValue("backLinks"),
                })
                bf.push({
                    "key": "more",
                    "label": "MORE:",
                    "value": this.getTextValue("backMore"),
                })
            }else{
                // Push notification message
                // if(this.isUpdateMode){
                //     for(let x in bf){
                //         if(bf[x].key == "notification"){
                //             bf[x].value = cardData.resource.templateData.pushMessage;
                //         }
                //     }
                //     // bf.push({
                //     //     "key": "notification",
                //     //     "value": cardData.resource.templateData.pushMessage,
                //     //     "changeMessage": "[%@]",
                //     // })
                // }else{
                //     bf.push({
                //         "key": "notification",
                //         "value": "",
                //         "changeMessage": "[%@]",
                //     })
                // }
                // if found <a> html tag add attributedValue also, if does not make sure 
                // to remove attributedValue
                //const regex = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/gmi;
                for(let x in bf){
                    //let m = regex.exec(bf[x].value);
                    if(this.hasATag(bf[x].value)){
                        // copy attributedValue from value
                        bf[x].attributedValue = bf[x].value;
                    }else{
                        delete bf[x].attributedValue;
                    }
                }
            }

            console.log(cardData.passData.coupon.backFields);
            // correct barcodes
            if(cardData.passData.barcode.format == ""){
                cardData.passData.barcode.format = "NoBardcode";
            }
            if((cardData.passData.barcode.format == "PKBarcodeFormatPDF417") && this.isAddThisPassMode){
                cardData.passData.barcode.format = "NoBardcode";
            }
            cardData.passData.barcodes = [];
            cardData.passData.barcodes.push({ ...cardData.passData.barcode });
            if(cardData.passData.barcode.format == "NoBardcode"){
                cardData.resource.templateData.barcodeOther = {
                    enabled: false,
                    name: "",
                    value: "",
                    columnName: "",
                    chkCreateNewColumn: false
                }
            }else{
                cardData.resource.templateData.barcodeOther.enabled = true;
            }


            if (this.cardTypeClass.includes("event-ticket")) {
                // remove strip image
                if (cardData.resource.chkStripImage == false) {
                    cardData.resource.stripImage = null;
                }
                //cardData.resource.stripImage = null;
                // change coupon to eventTicket 
                cardData.passData.eventTicket = cardData.passData.coupon;
                delete cardData.passData.coupon;
                // check thumbnail
                if (cardData.resource.chkThumbnail == false) {
                    delete cardData.resource.thumbnail;
                }
            }
            if (this.cardTypeClass.includes("add-this-pass")) {
                // remove header 
                //cardData.passData.eventTicket.headerFields = [];
                // remove label
                for (let x in cardData.passData.eventTicket.primaryFields) {
                    cardData.passData.eventTicket.primaryFields[x].label = "";
                }
                for (let x in cardData.passData.eventTicket.secondaryFields) {
                    cardData.passData.eventTicket.secondaryFields[x].label = "";
                }
                for (let x in cardData.passData.eventTicket.auxiliaryFields) {
                    cardData.passData.eventTicket.auxiliaryFields[x].label = "";
                }
            }
            if (this.cardTypeClass.includes("mindfire")) {
                // change coupon to eventTicket 
                cardData.passData.eventTicket = cardData.passData.coupon;
                delete cardData.passData.coupon;

                // remove headerFields 
                cardData.passData.eventTicket.headerFields = [];
                // make sure we have 1 secondardy fields
                cardData.passData.eventTicket.secondaryFields = [cardData.passData.eventTicket.secondaryFields[0]];

                // remove thumbnail
                delete cardData.resource.thumbnail;
                
                if(cardData.resource.chkBackgroundImage == false){
                    delete cardData.resource.backgroundImage;
                }
            }
            // add header to templateData
            if(this.Headers.length>0){
                cardData.resource.templateData.headers = this.clone(this.Headers);
                cardData.resource.templateData.sampleData = this.clone(this.SampleData);
            }
            //cardData.resource.templateData
            return cardData;
        },
        saveAsJson() {
            var text = JSON.stringify(this.getCardData(), null, 4);
            var url = window.URL.createObjectURL(new Blob([text], { type: "text/plain" }));
            var anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "passData.json";
            // (E) "FORCE DOWNLOAD"
            // NOTE: MAY NOT ALWAYS WORK DUE TO BROWSER SECURITY
            // BETTER TO LET USERS CLICK ON THEIR OWN
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
        },
        checkPass() {
            // has header field
            var headers = this.applPassData.coupon.headerFields;
            var primary = this.applPassData.coupon.primaryFields;
            var message = "";
            // if(message == "" && (!headers[0].value || !headers[0].label)){
            //     message = "Header field can not empty";
            // }
            // if(message == "" && (!primary[0].value || !headers[0].label)){
            //     message = "Primary field can not empty";
            // }
            if (message != "") {
                this.$swal.fire({
                    title: 'Oops!',
                    html: `<span>${message}</span>`,
                    ...csvUtils.getSwalYesNoOption(),
                });
                return false;
            }
            return true;
        },
        async createPassClick() {
            if (!this.checkPass()) {
                return;
            }
            await this.createPass();
        },
        async createAssThisPassClick(){
            this.$swal.fire({
                title: 'Creating pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            await this.saveClick();

            var passTagID = "";
            var csvData = "";
            var passKey = "";
            var passID = this.currentPassID;
            if(this.currentPass!=null ){
                passKey = this.currentPass.passKey;
                passID = this.currentPass.id;
            }
            //var mode = this.addThisPassMode;
            let formData = new FormData();           
            formData.append("cmd", 'passesUpdate');           
            formData.append("acctID", this.$store.getters.user.account);   //acctID         
            formData.append("progID", passID);   //id      // from addTHispass table
            formData.append("mode", this.addThisPassMode);       //"passinfo"  "onestop"
            formData.append("passKey", passKey);          //""
            formData.append("passTagID", passTagID);      //""    
            formData.append("passData", JSON.stringify(this.getCardData()));        //JSON   
            formData.append("tagsfile", csvData);    //CSV
            console.log(formData);
            var ret = await axios.post("https://davincistage.mindfireinc.com/admin/", formData);
            console.log(ret);
            //https://addthispass.com/pass/?pass=3nXK&qr=true&t=
            var qrCodeURL = `https://addthispass.com/pass/?pass=${ret.data.passKey}&qr=true&t=`;
            console.log("qrCodeURL",qrCodeURL,"passUrl",this.passUrl);
            this.passQRCodeImage = await this.getQRCodeImage(qrCodeURL);                
            this.$swal.close();
        },
        async syncWithAddThisPass(taskID){
            // var passTagID = "";
            var csvData = "";
            // var passKey = "";
            // var passID = this.currentPassID;
            // if(this.currentPass!=null ){
            //     passKey = this.currentPass.passKey;
            //     passID = this.currentPass.id;
            // }
            //var mode = this.addThisPassMode;
            let formData = new FormData();           
            formData.append("cmd", 'passesUpdate');           
            formData.append("acctID", this.$store.getters.user.account);   //acctID         
            formData.append("progID", this.currentPassID);   //id      // from addTHispass table
            formData.append("mode", "onestop");       //"passinfo"  "onestop"
            // formData.append("passKey", passKey);          //""
            // formData.append("passTagID", passTagID);      //""    
            formData.append("passData", JSON.stringify(this.getCardData()));        //JSON   
            formData.append("tagsfile", csvData);    //CSV
            // formData.append("taskID",taskID);
            console.log( new URLSearchParams(formData).toString());
            var backendEndpoint = "https://davincistage.mindfireinc.com/admin/backend.php";
            if(this.server=="prod"){
                backendEndpoint = "https://davinci.mindfireinc.com/admin/backend.php";
            }
            //}
            console.log("backendEndpoint",backendEndpoint);            
            var ret = await axios.post(backendEndpoint, formData);
            console.log("syncWithAddThisPass",ret);
        },
        // async getStageDomain(){
        //     var ret = await axios.get(this.csvProcessorEndPoint+"?cmd=getStageDomain");
        //     console.log("getStageDomain", ret.data);
        //     return ret.data.result;
        // },
        async fixImageDataURL(obj,name){
            console.log("fixImageDataURL",obj, name);
            if(obj[name]==null){
                return;
            }
            if(obj[name].indexOf("data:image/") == 0){
                let dataUrl = obj[name];
                let contentType = dataUrl.split(";")[0].substring(5);
                let suffix = contentType.split("/")[1];

                let blob = utils.dataURLtoBlob(dataUrl);
                console.log(blob);
                //console.log(contentType,suffix);
                let presignedUrl = await this.getPreSignedURL(contentType,suffix,"appData/cardBuilder/");
                var uploadRet = await this.getCleanAxios().put(presignedUrl, blob, {
                    headers: {
                        'Content-Type': contentType,
                        'x-amz-acl': 'public-read',
                    },
                    // onUploadProgress: progressEvent => {
                    //     //console.log(progressEvent)
                    //     if(progressCallbak){
                    //         progressCallbak({
                    //             progressEvent: progressEvent,
                    //         })
                    //     }
                    // },
                });       
                obj[name] = presignedUrl.split('?')[0];
                console.log(obj[name],uploadRet);
                //console.log(signedResult);
                
            }
        },
        async fixImageDataURLs(cardData){
            console.log("fixImageDataURLs start",cardData);
            await this.fixImageDataURL(cardData.resource,"logo");
            await this.fixImageDataURL(cardData.resource,"backgroundImage");
            await this.fixImageDataURL(cardData.resource,"stripImage");
            await this.fixImageDataURL(cardData.resource,"thumbnail");
            console.log("fixImageDataURLs done",cardData);
            return cardData;
        },
        async saveToAppData(){
            console.log("saveToJWT");
            let cardData = await this.fixImageDataURLs(this.getCardData());
            let jwtCardData = this.clone(cardData);
            jwtCardData.resource.dbFields = {
                createDate: new Date().toISOString(),
                updateCount: 0,
            }
            // if(this.debug){
            //     //throw new Error("DEBUG");
            //     return;
            // }
            
            //if(jwtCardData.resource.chkBackgroundImage == false){
            // jwtCardData.resource.backgroundImage = null;
            // jwtCardData.resource.logo = null;
            // jwtCardData.resource.thumbnail = null;
            //}
            let ret = await this.appUIPost(this.app.passUI.passData,jwtCardData.resource.templateData.templateName,jwtCardData);
            ret.cardData = cardData;        
            ret.data = {
                result: {
                    insertId: ret._id,
                }
            };                        
            console.log("saveToAppData", ret);       
            return ret; 
        },
        async saveToDB() {
            if(this.useMongoDB){
                return this.saveToAppData();
            }else{
                //save to database
                let cardData = this.getCardData();
                let ret = await axios.post(this.endPoint, {
                    cmd: "dbAdd",
                    acctID: this.$store.getters.user.account,
                    data: cardData,
                });
                console.log("saveToDB", ret);
                ret.cardData = cardData;
                return ret;
            }
        },
        async updateToDB(){
            if(this.useMongoDB){
                console.log("updateToJWT");
                //let cardData = this.getCardData();
                let cardData = await this.fixImageDataURLs(this.getCardData());
                let jwtCardData = {... cardData};
                if("dbFields" in jwtCardData.resource){
                    //jwtCardData.resource.dbFields.lastModified = new Date().toISOString();
                    if(jwtCardData.resource.dbFields.updateCount == undefined){
                        jwtCardData.resource.dbFields.updateCount = 0;
                    }else{
                        jwtCardData.resource.dbFields.updateCount++;
                    }
                    //jwtCardData.resource.dbFields.passKey = "FAKEKEY" + jwtCardData.resource.dbFields.updateCount;
                // }else{
                //     jwtCardData.resource.dbFields = {
                //         createDate: new Date().toISOString(),
                //         passKey:"FAKEKEY2",
                //         updateCount: 0,
                //     }
                }
                //jwtCardData.resource.templateData.templateName
                let ret = await this.appUIUpdate(this.currentPassID,jwtCardData,{passKey:"XXXX",name:jwtCardData.resource.templateData.templateName})
                ret.cardData = cardData;        
                console.log("updateToJWT", ret);        
                return ret;
            }else{
                var ret = await axios.post(this.endPoint, {
                    cmd: "dbUpdateByID",
                    acctID: this.$store.getters.user.account,
                    data: this.getCardData(true),
                    id: this.currentPassID,
                });
                console.log("updateToDB", ret);
                return ret;
            }
        },
        async saveInstanceToDB(param) {
            //save to database
            var ret = await axios.post(this.endPoint, {
                cmd: "dbAddPassInstance",
                acctID: this.$store.getters.user.account,
                param: param,
            });
            console.log("saveInstanceToDB", ret);
            return ret;
        },        
        async saveClick() {
            var ret;
            console.log("saveClick","saving",this.currentPassID);
            if (this.currentPassID!=null) {
                // loading from id
                //this.loadByID(this.$route.query.id);
                //alert("need to update", this.$route.query.id);
                ret = await this.updateToDB();
                console.log(ret);
            } else {
                ret = await this.saveToDB();
                this.currentPassID = ret.data.result.insertId;
            }
            console.log("saveClick","done");
            return ret;
        },
        async newClick(){
            this.loadByDefault(this.applResouce.templateData.cardType);
        },
        async exportClick(){
            var text = JSON.stringify(this.getCardData(), null, 4);
            var url = window.URL.createObjectURL(new Blob([text], { type: "text/plain" }));
            var anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${this.applResouce.templateData.templateName}.json`;
            // (E) "FORCE DOWNLOAD"
            // NOTE: MAY NOT ALWAYS WORK DUE TO BROWSER SECURITY
            // BETTER TO LET USERS CLICK ON THEIR OWN
            anchor.click();
            window.URL.revokeObjectURL(url);
            anchor.remove();
        },
        async checkUpload(e){
            console.log("checkJSON",e); // e == this.localFileInput
            if(e == null){
                return;
            }
            if (e.name.indexOf("json")<0) {
                //alert("Invalid file type");
                //this.localFileInput = undefined;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid file type',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return 
            }
            const reader = new FileReader();
            let self = this;
            reader.onload = function(event) {
                const passJSON = JSON.parse(event.target.result);
                //document.getElementById('fileContent').textContent = fileContent;
                //console.log("load from file",fileContent);
                self.setCardData(passJSON);
                //self.templateName = self.applResouce.templateData.templateName;
                self.currentPassID = null;   
                self.currentPass = null;
                self.applyCardData();     
                this.localFileInput  = null  

            };
            reader.readAsText(e);
            //clear error;
            // let uploadUrl = await this.uploadFileToS3(this.localFileInput);
            // console.log("uploadUrl",uploadUrl);
            // await this.uploadFromUrl(uploadUrl);
            // alert("Done");
        },
        async importClick(){
            this.$refs.localFileInput.$refs.input.click();
        },
        async preferenceClick(){
            this.showPreference = true;
            console.log("applResouce", this.applResouce);
            console.log("applPassData", this.applPassData);
            this.$nextTick(async () => {
                this.$refs.preference.initialize(this.applResouce.dbFields.passKey);
            });
        },
        async loadClick(){
            if(this.useMongoDB){
                console.log(this.loadItems);
                this.passDataTable.items = this.loadItems;
                this.showLoadDialog = true;
            }else{
                this.showLoadDialog = true;
                this.passDataLoading = true;
                var ret = await axios.post(this.endPoint, {
                    cmd: "dbGetByAccount",
                    acctID: this.$store.getters.user.account,
                });            
                this.passDataLoading = false;
                console.log(ret);
                // filter that table if in add this pass mode
                this.passDataTable.items = [];
                for(var x in ret.data.result){
                    console.log(ret.data.result[x]);
                    if(this.isAddThisPassMode){
                        if(ret.data.result[x].status == null){
                            this.passDataTable.items.push(ret.data.result[x]);    
                        }
                    }
                    if(this.isMindFireMode){
                        if(ret.data.result[x].status == "MF"){
                            this.passDataTable.items.push(ret.data.result[x]);    
                        }
                    }
                    
                }
            }
            //this.passDataTable.items = ret.data.result;
        },
        async createPass() {
            var saveRet = await this.saveClick();
            console.log("saveRet",saveRet);
            var getPassInstanceByTemplateIDRet = await axios.post(this.endPoint, {
                cmd: "dbGetPassInstanceByTemplateID",
                acctID: this.$store.getters.user.account,
                id: this.currentPassID,
            });
            console.log("getPassInstanceByTemplateIDRet",getPassInstanceByTemplateIDRet);
            var cardData = this.getCardData();
            if(getPassInstanceByTemplateIDRet.data.result.length > 0){
                this.$swal.fire({
                    title: 'Updating pass',
                    html: `<span></span>`,
                });
                this.$swal.showLoading();
                console.log("update existing instance");
                var record = getPassInstanceByTemplateIDRet.data.result[0];
                //check if it a dynamic pass
                if(record.fileID == ""){
                    console.log("dynamic ndo nothing");
                }else{
                    var updatePassRet = await axios.post(this.endPoint, {
                        cmd: "updatePass",
                        acctID: this.$store.getters.user.account,
                        serial: record.cardSerial,
                        data: cardData,
                    });
                    console.log("updatePassRet",updatePassRet);
                    var updatePassInstanceByTemplateIDRet = await axios.post(this.endPoint, {
                        cmd: "dbUpdatePassInstanceByTemplateID",
                        acctID: this.$store.getters.user.account,
                        param: {
                            templateID: this.currentPassID,
                            fileID: updatePassRet.data.result.fileID,
                            data: {
                                "passURL": updatePassRet.data.result.Location,
                                "templateData": cardData.resource.templateData,
                            }
                        }
                    })
                    console.log("updatePassInstanceByTemplateIDRet",updatePassInstanceByTemplateIDRet);
                    await this.getQRCodeImageFromInstanceID(record.Id);
                }
                //sendNotification
                var sendNotificationRet = await axios.post(this.endPoint, {
                    cmd: "sendNotification",
                    acctID: this.$store.getters.user.account,
                    templateID: this.currentPassID,
                })
                console.log(sendNotificationRet);

                this.$swal.close();
                return;
            }
            this.$swal.fire({
                title: 'Creating pass',
                html: `<span></span>`,
            });
            this.$swal.showLoading();

            console.log("create new instance");
            let param = {
                cmd: "createPass",
                acctID: this.$store.getters.user.account,
                data: cardData,
            }
            console.log(this.endPoint);
            // var ret = {"success":true,"version":"1.20 [zip + huge file support + localprocess + subsitute]","handlerName":"cardwallet.js","handlerVersion":"1.01","cmd":"createPass","result":{"Expiration":"expiry-date=\"Fri, 16 Dec 2022 00:00:00 GMT\", rule-id=\"pass\"","ETag":"\"b810ee2cb755388947d60cecfa4dddc5\"","VersionId":"lWAqewh7Nml3dyPKETk88iVew48pzxxU","Location":"https://mfdavinci.s3.us-west-1.amazonaws.com/file/pass/7f20969b-f925-41cd-89a3-e6bc3affab18.pkpass","key":"file/pass/7f20969b-f925-41cd-89a3-e6bc3affab18.pkpass","Key":"file/pass/7f20969b-f925-41cd-89a3-e6bc3affab18.pkpass","Bucket":"mfdavinci"}}
            var ret = await axios.post(this.endPoint, param);
            var result = ret.data.result;
            this.passUrl = result.Location;
            var fileID = result.fileID;
            var serial = result.serial;
            console.log("createPass", this.passUrl,fileID,serial);            
            var saveInstanceRet = await this.saveInstanceToDB({
                templateID: this.currentPassID,
                fileID,serial,
                data:{
                    "passURL": this.passUrl,
                    "templateData": cardData.resource.templateData,
                    // passURLs: [
                    //     ret.data.result.Location
                    // ]
                },
            });
            console.log("saveInstanceRet",saveInstanceRet);
            await this.getQRCodeImageFromInstanceID(saveInstanceRet.data.result.insertId);
            this.$swal.close();
        },
        async getQRCodeImageFromInstanceID(instanceID){
            //var instanceID = saveInstanceRet.data.result.insertId;
            var qrCodeURL = `https://mf1-unziptest.s3.us-west-1.amazonaws.com/index.html#/passView/${instanceID}`;
            console.log("qrCodeURL",qrCodeURL,"passUrl",this.passUrl);
            this.passQRCodeImage = await this.getQRCodeImage(qrCodeURL);
        },
        async getQRCodeImage(qrcodeData) {
            var myOption = {
                margin: "0",
                color: {
                    dark: "#000000FF",
                    light: "#FFFFFFFF",
                    lefttop: "#000000FF",
                    righttop: "#000000FF",
                    leftbottom: "#000000FF",
                    svgLogo: "#000000FF",
                    lefttopeye: "#fa6e79FF",
                    righttopeye: "#00bfffFF",
                    leftbottomeye: "#2d7cdaFF",
                    frame: "#000000FF",
                    frameText: "#FFFFFFFF",
                },
                cellMode: "square",
                width: 300,
                frame: "",
                frameText: "Hello World",
                svgLogo: "",
            };
            const response = await axios.post(this.qrcodeEndPoint, JSON.stringify({
                command: "qrcode",
                text: qrcodeData,
                option: myOption,
                imageFormat: "jpg", //png,jpg
                outputFormat: "dataUrl", //buffer,dataUrl
            }));
            var url = response.data.data;
            return url;
        },
        headerStyle() {
            var fontSize = 18;   //px
            fontSize = this.getFitFontSize(this.applPassData.coupon.headerFields[0].value, 90, fontSize)
            return {
                "font-size": `${fontSize}px`,
            }
        },
        primaryStyle(strip = null) {
            var fontSize = 62;   //px
            if (this.cardTypeClass.includes("event-ticket") && strip == null) fontSize = 17;
            fontSize = this.getFitFontSize(this.applPassData.coupon.primaryFields[0].value, 280, fontSize)
            return {
                "font-size": `${fontSize}px`,
            }
        },
        valueStyle(d) {
            var fontSize = 21.12;   //px
            if (this.cardTypeClass.includes("event-ticket")) {
               fontSize = 16;
            }
            var text = "";
            for (var i = 0; i < d.length; i++) {
                if (i == 0) {
                    text += this.moreFields[i].value;
                } else {
                    text += "___" + this.moreFields[i].value; //"____" for simulating space betweeen fields
                }
            }
            fontSize = this.getFitFontSize(text, 296, fontSize)
            return {
                "font-size": `${fontSize}px`,
            }
        },
        // textWidth(){
        //     console.log(this.getTextWidth(this.moreFields[0].value,"Roboto",21.12));
        //     console.log(this.getFitFontSize(this.moreFields[0].value,296,21.12));
        // },
        getTextWidth(text, fontName, fontSize) {
            if (this.fitTextCanvasContext == null) {
                var canvas = document.createElement("canvas");
                this.fitTextCanvasContext = canvas.getContext("2d");
            }
            var font = `${fontSize}px ${fontName}`;
            this.fitTextCanvasContext.font = font;
            const { width } = this.fitTextCanvasContext.measureText(text);
            return width;
        },
        getFitFontSize(text, width, maxFontSize, step = 0.5, font = 'Roboto') {
            var fs = maxFontSize;
            var textWidth = this.getTextWidth(text, font, fs);
            while ((textWidth - width) > 0) {
                //console.log("getFitFontSize",fs,textWidth,width);
                if (textWidth > width) {
                    fs -= step;
                    textWidth = this.getTextWidth(text, font, fs);
                }
            }
            return fs;
        },
        maxLabel(index) {
            var ret = 45;
            if(this.addThisPassMode){
                return ret;
            }
            if (this.moreFields.length == 2) {
                ret = 20;
            }
            if (this.moreFields.length == 3) {
                ret = 12;
            }
            return ret;
        },
        addClick(param) {
            console.log("addClick", param);
            this.moreFields.push(
                {
                    "key": "",
                    "label": "",
                    "value": ""
                }
            )
            this.activeObjectIndex++;
        },
        deleteMoreField(index) {
            var ret = [];
            for (var x = 0; x < this.moreFields.length; x++) {
                if (x != index) {
                    ret.push(this.moreFields[x]);
                }
            }
            this.moreFields = ret;
            if (this.activeObjectIndex > 0) {
                this.activeObjectIndex--;
            }
            //delete this.moreFields[index];
        },
        async loadByID(id,copyMode = false) {
            // check if component has finish load default image abd loadDefault
            if(this.isDefaultImageLoaded() == false){
                // wait for default image;
                console.log("loadByID wait for DefaultImage");
                setTimeout(()=> {this.loadByID(id,copyMode)},1000);
                this.loadByIDWaiting = true;    // make sure not showing default image while we have pass waiting
                return;
            }

            this.loadByIDWaiting = false;       // clear waiting 
            console.log("loadByID",id,copyMode);
            this.cardLoading = true;
            this.currentID = id;
            
            if(id in this.passCache && (new Date().getTime() - this.passCache[id + "time"] < this.cacheTime)){
                console.log("using cache", new Date().getTime() - this.passCache[id + "time"],this.cacheTime)    
                this.loadByCardData(id,this.passCache[id]);
            }else{
                var result = await axios.post(this.endPoint, {
                    cmd: "dbGetByID",
                    id: id,
                    acctID: this.$store.getters.user.account,
                });
                console.log("loadByID", id, result);
                if (result.data.result.length > 0) {
                    var record = result.data.result[0];
                    var cardData = JSON.parse(record.data);
                    // this.setCardData(cardData);
                    // this.currentPassID = id;
                    // this.applyCardData();
                    this.loadByCardData(id,cardData);
                    this.passCache[id] = cardData;
                    this.passCache[id + "time"] = new Date().getTime();
                }
            }
            if(copyMode == true){
                this.makeACopy();
            }
            this.cardLoading = false;
            
        },
        async loadByCardData(id,cardData,callback=null){
            if(this.isDefaultImageLoaded() == false){
                // wait for default image;
                console.log("loadByCardData wait for DefaultImage");
                setTimeout(()=> {this.loadByCardData(id,cardData,callback)},1000);
                this.loadByIDWaiting = true;    // make sure not showing default image while we have pass waiting
                return;
            }      
            this.loadByIDWaiting = false;          
            var ret = this.setCardData(cardData);
            this.currentPassID = id;
            // if no HTML fake it as default
            if("backFields" in this.applResouce){
                if("html" in this.applResouce.backFields == false){
                    //this.applResouce.backFields.html = "";
                    this.$set(this.applResouce.backFields,"html","");
                }
            }
           
            this.applyCardData();
            if(callback){
                callback();
            }
            return ret;
        },
        headerHasID(){
            if(this.Headers.indexOf("_id")>=0){
                if(this.Headers.findIndex(item => "purl" === item.toLowerCase()) >=0){
                     return true;
                }
            }
            return false;
        },
        async loadByDefault(cardType = null){
            var data = this.clone(defaultPass);
            if(cardType!=null){
                data.resource.templateData.cardType = cardType;
            }else{
                data.resource.templateData.cardType = this.defaultCardType;
            }
            console.log("loadByDefault",data);
            //check if we have _id in sample data
            if(this.headerHasID()){
                data.resource.templateData.purlData.columnName = "_id";
                //data.resource.templateData.purlData.columnName = "_id+purl";
            }
            this.setCardData(data);
            this.applResouce.templateData.templateName = this.templateName ; //"New Pass " + utils.UTCDateTime();  
            this.currentPassID = null;   
            this.currentPass = null;
            var lst = ["messageInput","linkInput"];
            for(var x in lst){
                if(lst[x] in this.$refs){
                    var ref = this.$refs[lst[x]];
                    if(ref){
                        ref.init();
                    }
                }
            }
            this.applyCardData();       
        },
        getImageWidthFromDataURL(url,callback){
            let image = new Image();
            image.src = url;
            //let self=this;
            image.onload = function() {
                //console.log("applyCardData",this.width + 'x' + this.height);
                if(callback){
                    callback(this.width,this.height)
                }
                //self.logoWidth = this.width;
            }
        },
        applyCardData(){
            // get headers from prop
            if(this.headers.length > 0){
                console.log("applyCardData","from this.header",this.headers,this.sampleData);
                this.Headers = this.headers;
                this.SampleData = this.sampleData;
            }

            //this.root.style.setProperty("--value-color", this.applPassData.foregroundColor);
            this.varValueColor = this.applPassData.foregroundColor;
            //this.root.style.setProperty("--label-color", this.applPassData.labelColor);
            this.varLabelColor = this.applPassData.labelColor;
            if(("thumbnail" in this.applResouce == false)||(this.applResouce.thumbnail == null)){
                this.applResouce.thumbnail = this.defaultImage.thumbnail;
                // adjust width of logo
                this.getImageWidthFromDataURL(this.applResouce.thumbnail,(width)=>{
                    this.thumbnailWidth = width;
                })
            }
            if(("backgroundImage" in this.applResouce == false)||(this.applResouce.backgroundImage == null)){
                this.applResouce.backgroundImage = this.defaultImage.backgroundImage;
            }
            if(("stripImage" in this.applResouce == false)||( this.applResouce.stripImage == null) ){
                this.applResouce.stripImage = this.defaultImage.stripImage;
            }
            this.getImageWidthFromDataURL(this.applResouce.logo,(width,height)=>{
                    this.logoWidth = width;
                    this.logoHeight = height;
            })
            if(("templateData" in this.applResouce)){
                let found = false;
                let props = ["messageData","linkData","backsideMessage"];
                for(var i in props){
                    let p = props[i];
                    if(p in this.applResouce.templateData){
                        if(this.applResouce.templateData[p].value !="" || this.applResouce.templateData[p].columnName){
                            found = true;
                            break;
                        }
                    }
                }
                if(found){
                    //console.log("aaaaa")
                    this.showOptions = true;
                }else{
                    this.showOptions = false;
                }
            }
            if((this.applPassData.barcode.format == "PKBarcodeFormatPDF417") && this.isAddThisPassMode){
                this.applPassData.barcode.format = "NoBardcode";
            }

            if("headers" in this.applResouce.templateData && this.applResouce.templateData.headers.length > 0){
                if(this.headers.length == 0){
                    console.log("using header from card data");
                    // Away use from List Service                    
                    // this.Headers = this.applResouce.templateData.headers; 
                    this.SampleData = this.applResouce.templateData.sampleData;
                }
            }
            if(("showWelcomeMessage" in this.applResouce.templateData) == false){
                // this.applResouce.templateData.showWelcomeMessage = true;
                this.$set(this.applResouce.templateData,"showWelcomeMessage",true);
                console.log("showWelcomeMessage",this.applResouce.templateData.showWelcomeMessage);
            }
            if(("defaultAppleWallet" in this.applResouce.templateData) == false){
                // this.applResouce.templateData.showWelcomeMessage = true;
                this.$set(this.applResouce.templateData,"defaultAppleWallet","User");
                // this.$set(this.applResouce.templateData,"chkBlurBackgroundImage",true);
                // this.$set(this.applResouce.templateData,"chkBackgroundVideo",false);
                
                console.log("defaultAppleWallet",this.applResouce.templateData.defaultAppleWallet);
            }
            if(("defaultAndroidWallet" in this.applResouce.templateData) == false){
                this.$set(this.applResouce.templateData,"defaultAndroidWallet","User");
                console.log("defaultAndroidWallet",this.applResouce.templateData.defaultAndroidWallet);
            }
            if(("timeZone" in this.applResouce.templateData) == false){
                this.$set(this.applResouce.templateData,"timeZone","");
            }
            // SET TIMEZONE to Eastern (AddThisPass default)
            // if(this.applResouce.templateData.timeZone == ""){
            //     let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            //     const tzData = this.timezones.filter(item => item['value'] === tz);
            //     console.log("tzData: ", tzData);
            //     if (tzData.length == 0) tz = "America/New_York";
            //     this.$set(this.applResouce.templateData,"timeZone",tz);
            //     console.log("timeZone",this.applResouce.templateData.timeZone);
            // }
            this.$set(this.applResouce.templateData,"timeZone","America/New_York");

            // // adjust width of logo
            // let image = new Image();
            // image.src = this.applResouce.logo;
            // let self=this;
            // image.onload = function() {
            //     //console.log("applyCardData",this.width + 'x' + this.height);
            //     self.logoWidth = this.width;
            // }

        },
        makeACopy(){
            this.currentPassID = null;
            this.currentPass = null;
            this.applResouce.templateData.templateName = this.applResouce.templateData.templateName + " (copy)";
        },
        async loadSelect(){
            this.showLoadDialog=false
            this.loadByCardData(null,this.selected[0].data);
            this.makeACopy();
        },
        // async passDataTableRowClick(param){
        //     console.log("passDataTableRowClick",param);
        //     // this.showLoadDialog = false;
        //     // if(this.currentPassID != param.id){
        //     //     await this.loadByID(param.id);
        //     // }
        //     // if(this.debugMode == false){ // davinci mode
        //     //     this.makeACopy();
        //     // }else{
        //     //     this.currentPass = {... param};
        //     // }
            
        // },
        // doCSVInput(callback){
        //     var lst = ["purlInput"]; //,"messageInput","linkInput"];
        //     if(this.requireMessageInput){
        //         lst.push("messageInput");
        //     }
        //     if(this.requireLinkInput){
        //         lst.push("linkInput");
        //     }
        //     for(var x in lst){
        //         if(lst[x] in this.$refs){
        //             var ref = this.$refs[lst[x]];
        //             callback(ref,lst[x]);
        //         }
        //     }
        // },
        canSave(){
            //console.log("canSave SampleData",this.SampleData);
            let ret = true;
            this.isBackgroundVideoValid;
            this.applResouce.chkBackgroundVideo;
            // this.doCSVInput((ref,name)=>{
            //     if(ref != undefined){
            //         if(ref.isValid()==false){
            //             console.log("canSave false",name);
            //             ret = false;
            //         }
            //     }
            // })
            if(this.applPassData.barcode.format != "NoBardcode"){
                // using barcode 
                if(this.applResouce.templateData.barcodeOther.chkCreateNewColumn == true){
                    if(this.applResouce.templateData.barcodeOther.value == ""){
                         console.log("canSave false","barcodeOther.value");
                        return false;
                    }
                }else{
                    if(this.applResouce.templateData.barcodeOther.columnName == ""){
                        console.log("canSave false","barcodeOther.columnName");
                        return false;                    
                    }
                }
                
            }
            //if(this.applResouce.chkThumbnail){
                if(this.applPassData.coupon.primaryFields[0].value == ""){
                    console.log("canSave false","primaryFields[0].value empty");
                    return false;
                }
            //}
            console.log("canSave",ret,this.isFormValid,(this.isBackgroundVideoValid || this.applResouce.chkBackgroundVideo == false));
            return ret && this.isFormValid && (this.isBackgroundVideoValid || this.applResouce.chkBackgroundVideo == false) ;
            // var lst = ["purlInput","messageInput","linkInput"];
            // for(var x in lst){
            //     if(lst[x] in this.$refs){
            //         var ref = this.$refs[lst[x]];
            //         if(ref.isValid()==false){
            //             console.log("canSave false",lst[x]);
            //             return false;
            //         }
            //     }
            // }
            // // if(this.applResouce.templateData.uniqueIDColumn 
            // //     && this.applResouce.templateData.optionalMessageColumn 
            // //     && this.applResouce.templateData.optionalLinkColumn 
            // // ) return true;
            // return true;
        },
        // onLoadFrom(item){
        //     console.log("onLoadFrom",item);
        // }
        async loadByDefaultEx(cardType){
            console.log("loadByDefaultEx",this.currentID);
            // if("logo" in this.defaultImage == false){
                await this.loadDefaultImage();
                await this.loadByDefault(cardType)
            // }else{
            //     await this.loadByDefault(cardType)
            // }
        },
        isDefaultImageLoaded(){
            return ("logo" in this.defaultImage);
        },
        async loadDefaultImage(callback){
            if(this.isDefaultImageLoaded() == false){
                let logoPromise = this.getImageAsDataURL("https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/logo.png");
                let stripPromise = this.getImageAsDataURL("https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/stripImage.png");
                let backgroundPromise = this.getImageAsDataURL("https://mfdavinci.s3.us-west-1.amazonaws.com/image/cardbuilder/background.png");
                let results = await Promise.all([logoPromise,stripPromise,backgroundPromise]);
                //  /*require("../assets/logo.png")*/, (result => {
                this.defaultImage.logo = results[0];
                this.defaultImage.thumbnail = results[0];
                this.defaultImage.stripImage = results[1];
                this.defaultImage.backgroundImage = results[2];
                console.log("loadDefaultImage done",this);
                // this.$forceUpdate();
                if(callback){
                    callback()
                }
            }
        },
        async moreOptionClick(){
            // console.log("moreOptionClick",this.showOptions);
            this.showOptions = !this.showOptions;
            // console.log("moreOptionClick",this.applResouce.templateData.hasOptions);
        },
        async editorInit(editor){
            console.log("editorInit");
            require('brace/ext/language_tools') //language extension prerequsite...
            require('brace/mode/html')                
            require('brace/mode/javascript')    //language
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/snippets/javascript') //snippet
            console.log(editor);
            editor.setOption("showPrintMargin", false);
            editor.focus();
            this.aceEditor = editor;
        },
        async showHtmlClick(){
            this.showHtmlEditorDialog = !this.showHtmlEditorDialog;
            this.passHtml = this.applResouce.backFields.html;
            if(this.aceEditor){
                this.aceEditor.focus();
            }
        },
        async saveHtmlClick(){
            this.showHtmlEditorDialog = false;
            this.applResouce.backFields.html = this.passHtml;
        },
        async backgroundVideoUrlChange(link){
            this.isBackgroundVideoValid = false;
            if(link!=null){
                let vinfo = this.GetVideoInfo(link);
                if(vinfo!=null){
                    console.log("backgroundVideoUrlChange",link,vinfo);
                    this.applResouce.backgroundVideoThumbnailUrl = vinfo.img;
                    this.isBackgroundVideoValid = true;
                    this.$nextTick(() => {
                        this.$emit("canSave",this.canSave());
                    })

                    return;
                }
            }
            // try create thumbnail
            this.createThumbnail(link,(thumbnailUrl) => {
                //console.log("createThumbnail",thumbnailUrl);
                if(thumbnailUrl != null){
                    this.applResouce.backgroundVideoThumbnailUrl = thumbnailUrl;
                    this.isBackgroundVideoValid = true;
                }else{
                    // this.isBackgroundVideoValid = false;
                }
                this.$forceUpdate();
                this.$nextTick(() => {
                        this.$emit("canSave",this.canSave());
                })
            });
            this.applResouce.backgroundVideoThumbnailUrl ="https://martech.org/wp-content/uploads/2015/01/video-generic-ss-1920.jpg";
        },
        GetVideoInfo(link){
            {
                let m = link.match(/youtube.*v=([^&]+)/i);
                if(m != null){
                    let ret = {
                    type: "youtube",
                    vid: m[1],
                    img: "https://img.youtube.com/vi/" + m[1] + "/0.jpg",
                    }
                    //console.log(ret);
                    return ret;
                }
            }
            //Support for short version 
            //https://youtu.be/fr1vCbuBjtI  
            {
                let m = link.match(/youtu.be\/([^&]+)/i);
                if(m != null){
                    let ret = {
                    type: "youtube",
                    vid: m[1],
                    img: "https://img.youtube.com/vi/" + m[1] + "/0.jpg",
                    }
                    //console.log(ret);
                    return ret;
                }
            }
            //Support shorts
            //https://www.youtube.com/shorts/_8Kq0mP1ZB0
            {
                let m = link.match(/youtube.*\/shorts\/([^&]+)/i);
                if(m != null){
                    let ret = {
                    type: "youtube",
                    vid: m[1],
                    img: "https://img.youtube.com/vi/" + m[1] + "/0.jpg",
                    }
                    //console.log(ret);
                    return ret;
                }
            }            
            // Suport vimeo  https://vimeo.com/868983841
            {
                let m = link.match(/vimeo.com\/([0-9]+)/i);
                if(m != null){
                    let ret = {
                    type: "vimeo",
                    vid: m[1],
                    img: `https://vumbnail.com/${m[1]}.jpg`, // "https://img.youtube.com/vi/" + m[1] + "/0.jpg",
                    }
                    //console.log(ret);
                    return ret;
                }     
            }       
            return null;
        },
        //https://mfdavinci.s3.us-west-1.amazonaws.com/image/sample-5s.mp4
        createThumbnail(videoUrl,callback){
            // Replace 'VIDEO_URL' with the URL of your video file
            //const videoUrl = 'VIDEO_URL';
            
            // Create a video element
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous'; // Enable cross-origin for video elements (if needed)
            
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            // Set video source and handle when it's loaded
            video.addEventListener('loadeddata', function () {
                // Set canvas dimensions to match the video dimensions
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                video.currentTime = 0.1;
                
                video.addEventListener('seeked', function () {
                    // Draw the first frame of the video on the canvas
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    
                    // Convert the canvas content to a data URL (thumbnail)
                    const thumbnailUrl = canvas.toDataURL('image/jpeg'); // You can use other formats like 'image/png'
                    console.log("createThumbnail",thumbnailUrl);
                    // Use the thumbnail URL as needed (e.g., display it on the page)
                    //const thumbnailImage = new Image();
                    //thumbnailImage.src = thumbnailUrl;
                    if(callback){
                        callback(thumbnailUrl)
                    }
                    // Append the thumbnail image to the document or perform other actions
                    //document.body.appendChild(thumbnailImage);
                });
            });
            video.addEventListener('error', function(err){
                console.log("error",err);
                if(callback){
                    callback(null);
                }                
            });
            video.src = videoUrl;
        },
        async loadHeaderFromListService(moreItem = []){
            let lsResult;
            // if (process.env.VUE_APP_JWT == "mockup") {
            //     //load from mockup instead
            //     const result = await fetch(process.env.BASE_URL + "mockup.json");
            //     let mockupJson = await result.json();
            //     console.log("loadHeaderFromListService","mockup",mockupJson);
            //     lsResult = mockupJson.LSGetHeader.result;
            // }else{
                let lsEndPoint = `${this.getListServiceEndPoint()}/api/contact-service/v1/imports?find=[{%22$match%22:{%22status%22:{%22$in%22:[%22completed%22,%22rejected%22]}}}]&limit=1&skip=0`;
                //let lsEndPoint = `${this.getListServiceEndPoint()}/api/contact-service/v1/imports?find=[{%22$match%22:{%22status%22:{%22$in%22:[%22completed%22,%22rejected%22]}}}]&limit=100&skip=0&select=header status name processedRecords recordsDeleted`;
                var resp = await axios.get(lsEndPoint);
                console.log("loadHeaderFromListService",resp.data);
                lsResult = resp.data;
            // }
            let headers = [...moreItem];
            for(let item of lsResult){
                //console.log(item);
                for(let header of item.header){
                    //console.log(header)
                    //header in headers ?headers[header]+=1:headers[header]=1;
                    if(headers.indexOf(header)<0) headers.push(header);
                }
            }
            headers.sort();
            return headers;
            // process result
        },
        setMessageLine(index,value){
            switch(index){
                case 0: this.applPassData.coupon.primaryFields[0].value = value;break;
                case 1: this.applPassData.coupon.secondaryFields[0].value = value;break;
                case 2: this.applPassData.coupon.secondaryFields[1].value = value;break;
            }
        },
        async showPass(){   // preview
            if(this.selected.length>0){
                this.$nextTick(() => {
                    if(this.useMongoDB){
                        this.$refs.cardBuilderPreview.loadByCardData(this.selected[0].id,this.selected[0].data)
                    }else{
                        this.$refs.cardBuilderPreview.loadByID(this.selected[0].id);
                    }
                });
            }
        },
        setUseLogoAsThumbnail(thumbnailUrl){
            if(this.applResouce.chkStripImage == false){
                console.log('setUseLogoAsThumbnail');
                if (thumbnailUrl == '') {
                    this.applResouce.useLogoAsThumbnail = true;
                } else {
                    this.applResouce.useLogoAsThumbnail = false;
                    this.applResouce.thumbnail = thumbnailUrl;
                }
                this.applResouce.chkThumbnail = true;
            }
        }
    },

    created() {
        console.log("created")
    },
    destroyed() {
    },
    async mounted() {
        // if (process.env.VUE_APP_JWT == "mockup") {
        //     //console.log("CSVProcessorView","mount","debug mode");
        //     axios.defaults.headers.common['Authorization'] = "bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpcCI6IjQ5LjIyOC4xMDIuMTE4IiwiZW1haWwiOiJrZHV0dGFAbWluZGZpcmVpbmMuY29tIiwicHUiOnRydWUsImF1Ijp0cnVlLCJwYSI6ZmFsc2UsImFpZCI6MzQzMDcsInBhaWQiOjE3NzIzLCJpc3MiOiJpZGVudGl0eS5tZGwuaW8iLCJpYXQiOjE2NDk2NjYxMjMsImV4cCI6MTY0OTY5NDkyM30.Z8bZvKeSg1I8L31F1LQY9AeEZu8gG3jYkzLAuOUQnn5lWjJmtb8Vqm3dPRbzhQKDYxqDyduCGoJUZ1AKkPH99K9qEZjFJdLzI_AoK6SHVjaBxC8PmKsm360-mNmsffbFCMZx7DVYwAuljmjNT-hKT3g-1mORLuSajgkvxz2M8KuGoZFyFvxRVKroQLBnVNE_YBJ64TZFZaBB0gDK9bVr-4pXz_wML2rxUbj9dL7L9ZOT1ZzbySpaS8zl7zx27PGdtWJQtNMTH5DGaH5Gx0gGRQOdJBQ9Oarb7OIRqnrD-rPr00Xl6rgght5l8Bedlz8ai2Vvmly6d-CJ0iifk9xHAQ";
        // }   
        console.log("mounted");     
        this.loadDefaultImage(()=>{
            if(this.autoLoadDefault){
                // if ("id" in this.$route.query) {
                //     // loading from id
                //     this.loadByID(parseInt(this.$route.query.id));
                // }else{
                    this.loadByDefault();
                    this.$nextTick(() => {
                        if(this.$refs.qrCodeForm){
                            this.$refs.qrCodeForm.validate();
                        }
                    });
                // }
            }
            //this.root.style.setProperty("--main-bg-color",this.backgroundColor);
            if(this.loadPassCommand!=null){
                if("data" in this.loadPassCommand){
                    this.loadByCardData(this.loadPassCommand.id,this.loadPassCommand.data);
                }else{
                    this.loadByID(this.loadPassCommand.passID,this.loadPassCommand.copy);
                }
            }
        });
        if(this.loadListServiceHeader){
            if(this.headers.length == 0){
                //try load header from ListService

                let headers = await this.loadHeaderFromListService(["_id","purl"]);
                this.lsHeaders = headers;
                if(this.lsHeaders.length>0){
                    console.log("set header from listservice",this.lsHeaders);
                    this.Headers = this.lsHeaders;
                    // let sampleData = [];
                    // for(let h of this.Headers){
                    //     sampleData[h]=h;
                    // }
                    this.SampleData = this.lsHeaders;
                }
            }
        }else{
            console.log("skip header from listservice");
        }
        
    },
    beforeUnmount() {

    },
    watch: {
        // "qrcodeUrl":{
        //     handler: function (newValue, oldValue) {
        //         if(newValue!=null){

        //         }
        //     }
        // },
        // value: function (newValue, oldValue) {
        //     console.log("CardBuilder watch value", newValue);
        //     if (newValue != null) {
        //         //this.setConfigByValue();
        //     }
        // },        
        "applPassData.backgroundColor": {
            handler: function (newValue, oldValue) {
                //console.log("backgroundColor",oldValue,"->",newValue);
                this.$nextTick(() => {
                    // this.root.style.setProperty("--bg-color", newValue);
                })
            }
        },
        "applPassData.foregroundColor": {
            handler: function (newValue, oldValue) {
                if(this.singleColor == true){
                    this.applPassData.labelColor = newValue;
                }
                //console.log("foregroundColor",oldValue,"->",newValue);
                this.$nextTick(() => {
                    //this.root.style.setProperty("--value-color", newValue);
                    this.varValueColor = newValue;
                })
            }
        },
        "applPassData.labelColor": {
            handler: function (newValue, oldValue) {
                //console.log("foregroundColor",oldValue,"->",newValue);
                this.$nextTick(() => {
                    //this.root.style.setProperty("--label-color", newValue);
                    this.varLabelColor = newValue;
                })
            }
        },
        "applResouce.chkThumbnail": {
            handler: function (newValue, oldValue) {
                if(newValue){
                    this.applResouce.chkStripImage = false;
                }
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },
        "applResouce.chkStripImage": {
            handler: function (newValue, oldValue) {
                if(newValue){
                    // console.log("applResouce.chkStripImage changed");
                    this.applResouce.chkThumbnail = false;
                }
            }
        },
        "applResouce.templateData.purlData":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("purlData change")
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                })
            },
        },
        "applResouce.templateData.messageData":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("messageData change")
                this.$nextTick(() => {
                this.$emit("canSave",this.canSave());
                })
            }
        },
        "applResouce.templateData.linkData":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("linkData change")
                this.$nextTick(() => {
                this.$emit("canSave",this.canSave());
                });
            }
        },
        "applResouce.templateData.qrcodeColumn":{
            deep: true,
            handler: function (newValue, oldValue) {
                console.log("qrcodeColumn change");
                
                this.$nextTick(() => {
                    //console.log(this.applResouce.templateData.qrcodeColumn);
                    if(this.$refs.qrCodeForm){
                        this.$refs.qrCodeForm.validate();
                        this.$nextTick(() => {
                            this.$emit("canSave",this.canSave());
                        });
                    }
                });
            }
        },
        "applResouce.backgroundVideoUrl":{
            handler: function (newValue, oldValue) {
                this.backgroundVideoUrlChange(newValue);
                // this.$forceUpdate();
            }
        },
        "applResouce.chkBackgroundVideo":{
            handler: function (newValue, oldValue) {
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },        
        "applPassData.barcode.format": {
            handler: function (newValue, oldValue) {
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },
        "applResouce.templateData.barcodeOther.columnName":{
            handler: function (newValue, oldValue) {
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },
        "applResouce.templateData.barcodeOther.chkCreateNewColumn":{
            handler: function (newValue, oldValue) {
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },        
        "applResouce.templateData.barcodeOther.value":{
            handler: function (newValue, oldValue) {
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },   
        "applPassData.coupon.primaryFields.0":{
            handler: function (newValue, oldValue) {
                this.$nextTick(() => {
                    this.$emit("canSave",this.canSave());
                });
            }
        },
        "applResouce.templateData.defaultAppleWallet":{
            handler: function (newValue, oldValue) {
                if(newValue == "Apple"){
                    this.applResouce.templateData.chkBlurBackgroundImage = true;
                    this.applResouce.chkBackgroundVideo = false;
                }
                // this.$nextTick(() => {
                //     this.$emit("canSave",this.canSave());
                // });
            }
        },
        "loadPassCommand":{
            handler: function (newValue, oldValue) {
                if(JSON.stringify(newValue) != JSON.stringify(oldValue)){
                    console.log("loadPassCommand",oldValue,"!=",newValue);
                    if("data" in newValue){
                        this.loadByCardData(newValue.id,newValue.data);
                    }else{
                        this.loadByID(newValue.passID,newValue.copy);
                    }
                }else{
                    // console.log("loadPassCommand skip: no changed");
                }
            }
        },
        "useLogoAsThumbnail":{
            handler: function (newValue, oldValue) {
                // if(newValue){
                //     this.applResouce.thumbnail = this.applResouce.logo;
                // }
            }
        },
        selected(newValue,old){
            // console.log("selected",newValue,old);
            if(old.length > 0 && newValue.length > 0){
                console.log("selected",old[0].id,newValue[0].id);
                // if(old[0].id == newValue[0].id){
                //     return;
                // }
            }
            this.showPass();
            this.$emit("selected",newValue);
        }
        // $route(to, from) {
        //     console.log("route change", from, "->", to);
        //     if ("id" in to.query) {
        //         this.loadByID(parseInt(to.query.id));
        //     }else{
        //         // new 
        //         this.loadByDefault();
        //     }
        // }
    },
};
</script>