<template>
    <v-container style="height:100%;max-width:100%;width:100%;">
        <v-row class="mt-0">
            <v-col class="pt-5" cols=3>
                <v-text-field v-if="locationMode == 'geofences'" v-model="geofencingConfig.definition.maxRange" label="Max Distanse" placeholder="300 to 500ft." hide-details dense @input="showSaveMaxRange()"></v-text-field> <!-- style="margin-top: 20px;" -->
                <div v-if="locationMode == 'geofences' && (geofencingConfig.definition.maxRange<300 || geofencingConfig.definition.maxRange>500)" style="padding: 0px 15px; font-size: small;font-style: italic;color: firebrick;">
                    Note: Value must be between 300 and 500 feet.
                </div>
            </v-col>
            <v-col cols=1>
                <v-btn v-if="locationMode == 'geofences' && canSaveMaxRange" @click="saveMaxRange()">Save</v-btn>
            </v-col>
            <v-col cols=8 class="text-right">
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on}">
                        <v-btn :disabled="locations.length==0" v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshTaskClick()"><v-icon>refresh</v-icon></v-btn>
                    </template>
                    Reload
                </v-tooltip>
                <!-- <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="locations.length==0 || geofencingConfig.definition.maxRange<300 || geofencingConfig.definition.maxRange>500" small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="sendtoAddthispassClick()"><v-icon>cloud_upload</v-icon></v-btn>
                    </template>
                    Send to Addthispass
                </v-tooltip> -->
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="importCSVClick()"><v-icon>upload_file</v-icon></v-btn>
                    </template>
                    Import CSV File
                </v-tooltip>
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="locations.length==0" small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="downloadAllClick()"><v-icon>download</v-icon></v-btn>
                    </template>
                    Download All
                </v-tooltip>
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="addNewLocationClick()"><v-icon>add</v-icon></v-btn>
                    </template>
                    Add New Location
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col cols=12>
                <v-data-table :headers="headers[locationMode]" :items="locations" :loading="locationDataLoading" @click:row="locationRowClick" :items-per-page="5" :single-expand="singleExpand" show-expand :expanded.sync="expanded" item-key="_id" :single-select=true sort-by="name" class="elevation-1" dense style="width: 100%;" height="40vh">
                    <template v-slot:no-data>
                        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                    </template>
                    <template v-slot:[`item.image`]="props">
                        <v-img :src=props.item.image height="64" width="64"></v-img>
                    </template>
                    <!-- <template v-slot:[`item.REDIRECT`]="props">
                        {{displayRedirect(props.item.REDIRECT)}}
                    </template>                     -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <v-row>
                            <v-col ><b>_id:</b> {{item._id}}<br><b>Redirect Option:</b> {{displayRedirect(item["REDIRECT"])}}</v-col>
                            <v-col >
                            <b>Location:</b> {{ item["LOCATION LATITUDE"] }}, {{ item["LOCATION LONGITUDE"] }}<br>
                            <b>Redirect URL:</b> {{item["REDIRECT URL"]}}
                            </v-col>
                            <v-col ><b>Suppression:</b> {{item["SUPPRESSION"]}}</v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog persistent v-model="dialog" max-width="600px">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Address</v-btn>
            </template> -->
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field dense v-model="editedItem['name']" label="Name"></v-text-field>
                            </v-col>
                            <v-col style="position:relative;" cols="6">
                                <v-text-field dense v-model="editedItem['image']" label="Image"></v-text-field>
                                <!-- <v-img :src="editedItem['image']" height="120" width="160" @click="cropUI.show=!cropUI.show"></v-img>
                                <v-icon v-show="true" @click="showCropUI('image',true)"
                                        style="position: absolute;bottom: 8px;right: 40px;cursor: pointer;font-size: small;background-color: white;border-radius: 5px;opacity: 0.8;">mode</v-icon>
                                <KCImageCropper ref="cropper" :key="cropUI.show" :width=cropUI.width :maxWidth=cropUI.maxWidth :maxHeight=cropUI.maxHeight
            :height=cropUI.height @crop="onCrop" v-model="cropUI.show" :src="editedItem['image']"></KCImageCropper> -->
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" v-if="locationMode == 'beacons'">
                                <v-text-field dense v-model="editedItem['physicalID']" label="Physical ID"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" v-if="locationMode == 'beacons'">
                                <v-text-field :disabled="editMode" dense v-model="editedItem['MAJOR']" label="Major"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" v-if="locationMode == 'beacons'">
                                <v-text-field :disabled="editMode" dense v-model="editedItem['MINOR']" label="Minor"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" v-if="locationMode == 'beacons'">
                                <v-text-field :disabled="editMode" dense v-model="editedItem['UUID']" label="UUID"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <!-- <v-text-field dense v-model="editedItem['LOCATION']" label="US Address"></v-text-field> -->
                                <KCGoogleAutoComplete :disabled="editMode" ref="addressX" id="map" classname="form-control" placeholder="" @change="addressChange" v-on:placechanged="getAddressData" label="US Address" :country="['us','ca']"></KCGoogleAutoComplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field :disabled="editMode" dense v-model="editedItem['LOCATION LATITUDE']" label="Latitude"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field :disabled="editMode" dense v-model="editedItem['LOCATION LONGITUDE']" label="Longitude"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field dense v-model="editedItem['MESSAGE']" label="Message"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <!-- <v-text-field dense v-model="editedItem['STATUS']" label="Status"></v-text-field> -->
                                <v-select hide-details v-model="editedItem['STATUS']" :items="status" label="Status" dense></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field dense v-model="editedItem['SUPPRESSION']">
                                    <template v-slot:label>
                                        <span>Suppression time (minutes) 
                                            <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" dense>help</v-icon>
                                                </template>
                                                The user will not see the same message twice during the suppression time
                                            </v-tooltip>
                                        </span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <!-- <v-text-field dense v-model="editedItem['REDIRECT']" label="Redirect Option"></v-text-field> -->
                                <v-select hide-details v-model="editedItem['REDIRECT']" :items="redirect" label="Redirect Option" dense></v-select>
                            </v-col>
                            <v-col v-show="editedItem['REDIRECT'] != '1'" cols="12" md="6">
                                <v-text-field dense v-model="editedItem['REDIRECT URL']" label="Redirect URL"></v-text-field>
                                <div v-if="canSaveRedirectURL == false" style="padding: 0px 15px; font-size: small;font-style: italic;color: firebrick;">
                                    Note: Use redirects to url require Redirect URL value.
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                    
                </v-card-text>

                <v-card-actions>
                    <!-- {{editedItem['LOCATION']}} -->
                    <v-spacer></v-spacer>
                    <v-btn :disabled="canSave == false" color="blue darken-1" text @click="save('dialog')">Save</v-btn>
                    <v-btn color="blue darken-1" text @click="closeClick">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogImportCSV" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                    <v-row dense class="py-1">
                        <v-col cols="12">
                            <v-file-input ref="localFileInput" accept=".csv" hide-details class="pt-1 shrink" v-model="localFileInput" label="CSV" prepend-icon="upload_file" ></v-file-input>
                        </v-col>
                        <v-col cols="12" v-if="locationMode=='geofences'">
                            <a href="https://mfdavinci.s3.us-west-1.amazonaws.com/appData/file/geofences-sample.csv">download a file sample here</a>
                        </v-col>
                        <v-col cols="12" v-else-if="locationMode=='beacons'">
                            <a href="https://mfdavinci.s3.us-west-1.amazonaws.com/appData/file/beacons-sample.csv">download a file sample here</a>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="importCSVConfirm">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="closeImportCSV">Cancel</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>    


    </v-container>
</template>
<script>
import axios from "axios";
import addThisPass from "../services/KCAddThisPass.js";
import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
// import KCImageCropper from "../components/KCImageCropper.vue";
import kcMixins from "../services/KCMixins.js";
import utils from '../services/KCUtils';
import { validateHeaderName } from 'http';
import { stringify } from 'querystring';
import { allowedNodeEnvironmentFlags } from "process";
var crypto = require('crypto');
export default {
    mixins: [kcMixins],   
    components: {
        KCGoogleAutoComplete,
        // KCImageCropper
    },
    props: {
        locationMode: {
            default: '',
        },
        maxRange: {
            default: 325,
        },
        masterHeader: {
            type: Object,
            default() {
                return {}
            }
        },
        passUIEndpoint: {
          type: String,
          //default: "https://8mdmei6e83.execute-api.us-west-1.amazonaws.com/test/api/csvprocessor?cmd=getDocument&name=walletPassConfig",
          default: process.env.VUE_APP_PASSUI_ENDPOINT?process.env.VUE_APP_PASSUI_ENDPOINT:"https://kiws7ng8z2.execute-api.us-west-1.amazonaws.com/stage/passui/process"
        },
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            davinciUrl: null,                 
            dialog: false,
            dialogDelete: false,
            dialogImportCSV: false,
            localFileInput: null,
            locationDataLoading: false,
            editMode: false,
            acctKey: '',
            passKey: '',
            headers: {
                geofences: [
                    { text: "Name", value: "name", sortable: true },
                    { text: "Image", value: "image", sortable: false },
                    { text: "US Address", value: "LOCATION", sortable: false },
                    // { text: "Latitude", value: "LOCATION LATITUDE", sortable: true },
                    // { text: "Longitude", value: "LOCATION LONGITUDE", sortable: true },
                    { text: "Message", value: "MESSAGE", sortable: false },
                    { text: "Status", value: "STATUS", sortable: false },
                    // { text: "Suppression", value: "SUPPRESSION", sortable: true },
                    // { text: "Redirect Option", value: "REDIRECT", sortable: true },
                    // { text: "Redirect URL", value: "REDIRECT URL", sortable: true },
                    // { text: '', value: 'data-table-expand' },
                    // { text: 'Actions', value: 'actions', sortable: false }
                ],
                beacons: [
                    { text: "Name", value: "name", sortable: true },
                    { text: "Image", value: "image", sortable: false },
                    { text: "Physical ID", value: "physicalID", sortable: false },
                    { text: "UUID", value: "UUID", sortable: true },
                    { text: "Major", value: "MAJOR", sortable: true },
                    { text: "Minor", value: "MINOR", sortable: true },
                    { text: "US Address", value: "LOCATION", sortable: true },
                    // { text: "Latitude", value: "LOCATION LATITUDE", sortable: true },
                    // { text: "Longitude", value: "LOCATION LONGITUDE", sortable: true },
                    { text: "Message", value: "MESSAGE", sortable: true },
                    { text: "Status", value: "STATUS", sortable: true },
                    // { text: "Suppression", value: "SUPPRESSION", sortable: true },
                    // { text: "Redirect Option", value: "REDIRECT", sortable: true },
                    // { text: "Redirect URL", value: "REDIRECT URL", sortable: true },
                    // { text: 'Actions', value: 'actions', sortable: false }
                ]
            },
            csvFields: {
                geofences: [
                    { displayName: "Name", codeName: "name", required: false },
                    { displayName: "Image", codeName: "image", required: false },
                    { displayName: "US Address", codeName: "LOCATION", required: true },
                    { displayName: "Latitude", codeName: "LOCATION LATITUDE", required: true },
                    { displayName: "Longitude", codeName: "LOCATION LONGITUDE", required: true },
                    { displayName: "Message", codeName: "MESSAGE", required: false },
                    { displayName: "Status", codeName: "STATUS", required: false },
                    { displayName: "Suppression", codeName: "SUPPRESSION", required: false },
                    { displayName: "Redirect Option", codeName: "REDIRECT", required: false },
                    { displayName: "Redirect URL", codeName: "REDIRECT URL", required: false }
                ],
                beacons: [
                    { displayName: "Name", codeName: "name", required: false },
                    { displayName: "Image", codeName: "image", required: false },
                    { displayName: "Physical ID", codeName: "physicalID", required: false },
                    { displayName: "UUID", codeName: "UUID", required: true },
                    { displayName: "Major", codeName: "MAJOR", required: true },
                    { displayName: "Minor", codeName: "MINOR", required: true },
                    { displayName: "US Address", codeName: "LOCATION", required: true },
                    { displayName: "Latitude", codeName: "LOCATION LATITUDE", required: true },
                    { displayName: "Longitude", codeName: "LOCATION LONGITUDE", required: true },
                    { displayName: "Message", codeName: "MESSAGE", required: false },
                    { displayName: "Status", codeName: "STATUS", required: false },
                    { displayName: "Suppression", codeName: "SUPPRESSION", required: false },
                    { displayName: "Redirect Option", codeName: "REDIRECT", required: false },
                    { displayName: "Redirect URL", codeName: "REDIRECT URL", required: false }
                ]
            },
            locations: [],
            editedIndex: -1,
            editedItem: {},
            defaultGeofence: {
                "LOCATION": "",
                "LOCATION LATITUDE": "",
                "LOCATION LONGITUDE": "",
                "MESSAGE": "",
                "STATUS": "enabled",
                "SUPPRESSION": "360",
                "REDIRECT": "1",
                "REDIRECT URL": ""
            },
            defaultBeacon: {
                "MAJOR": "",
                "MINOR": "",
                "UUID": "",                
                "LOCATION": "",
                "LOCATION LATITUDE": "",
                "LOCATION LONGITUDE": "",
                "MESSAGE": "",
                "STATUS": "enabled",
                "SUPPRESSION": "360",
                "REDIRECT": "1",
                "REDIRECT URL": ""
            },
            //defaultItem: {},
            //maxRange: this.defaultMaxRange,
            status: [{"value": "enabled", "text": "Enabled"}, {"value": "disabled", "text": "Disabled"}],
            redirect: [{"value": "1", "text": "Opens card"}, {"value": "2", "text": "Opens card then redirects to url"}, {"value": "3", "text": "Just redirects to url"}],
            //endpoint: '',
            geofencingConfig: {
                definition: {
                    maxRange: this.maxRange,
                }
            },
            canSaveMaxRange: false,
            additionalFields: ["_id","key","name","image","physicalID"],
            // locations data
            locationMapping: {},
            expanded: [],
            singleExpand: false,
            masterEditedItem: {},
            // crop
            cropUI: {
                show: false,
                mode: "",
                width: 200,
                height: 200,
                maxWidth: null,
            },
            cropEditImage: {

            },
            cropInited: false,
            initialCropImage: null,
        };
    },

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Address' : 'Edit Address'
        },
        canSaveRedirectURL(){
            let red = this.editedItem['REDIRECT'];
            if(red == '1' || red == '') return true;
            let url = this.editedItem['REDIRECT URL'];
            return utils.IsValidURL(url);
        },
        canSave(){
            console.log("canSave",this.editedItem,this.editedItem.name)
            if(this.editedItem){
                if(this.editedItem.name){
                    if(this.editedItem.name.trim().length == 0) {
                        console.log("canSave2 name",this.editedItem.name);
                        return false;
                    }
                }else{
                    return false;
                }
                if (this.locationMode == 'geofences') {
                    if(this.editedItem['LOCATION']){
                        // console.log("canSave2 LOCATION",this.editedItem['LOCATION'].trim().length == 0)
                        if(this.editedItem['LOCATION'].trim().length == 0){
                            console.log("canSave2 LOCATION",this.editedItem['LOCATION']);
                            return false;
                        } 
                    }else{
                        console.log("canSave2 LOCATION");
                        return false;
                    }
                    if(this.editedItem['LOCATION LATITUDE']){
                        let lat = this.editedItem['LOCATION LATITUDE'];
                        if(lat.length == 0){
                            console.log("canSave2 LATITUDE",this.editedItem['LOCATION LATITUDE']);
                            return false;
                        }
                    }else{
                        console.log("canSave2 LATITUDE");
                        return false;
                    }
                    if(this.editedItem['LOCATION LONGITUDE']){
                        let lon = this.editedItem['LOCATION LONGITUDE'];
                        if(lon.length == 0){
                            console.log("canSave2 LONGITUDE",this.editedItem['LOCATION LONGITUDE'])
                            return false;
                        } 
                    }else{
                        console.log("canSave2 LONGITUDE");
                        return false;
                    }
                } else {
                    if(this.editedItem['UUID']){
                        if(this.editedItem['UUID'].trim().length == 0){
                            console.log("canSave2 UUID",this.editedItem['UUID'])
                            return false;
                        }
                    }else{
                        console.log("canSave2 UUID");
                        return false;
                    }
                    if(this.editedItem['MAJOR']){
                        let major = this.editedItem['MAJOR'];
                        if(major.length == 0){
                            console.log("canSave2 MAJOR",this.editedItem['MAJOR']);
                            return false;
                        }
                    }else{
                        console.log("canSave2 MAJOR");
                        return false;
                    }
                    if(this.editedItem['MINOR']){
                        let minor = this.editedItem['MINOR'];
                        if(minor.length == 0){
                            console.log("canSave2 MINOR",this.editedItem['MINOR'])
                            return false;
                        }
                    }else{
                        console.log("canSave2 MAJOR");
                        return false;
                    }
                }
            }
            if(this.canSaveRedirectURL == false){
                console.log("canSave2 canSaveRedirectURL")
                return false;
            }
           
            // 
            // check if data changed
            if(JSON.stringify(this.editedItem) == JSON.stringify(this.masterEditedItem)){
                console.log("canSave2 not dirty")
                return false
            }
            return true;
        },
        endPoint(){
            if (this.locationMode=='beacons') {
                return 'card-beacons';
            }else{
                return 'card-locations';
            }
        },
        defaultItem(){
            let obj;
            if (this.locationMode=='beacons') {
                obj = JSON.parse(JSON.stringify(this.defaultBeacon))
            }else{
                obj = JSON.parse(JSON.stringify(this.defaultGeofence))
            }
            for(let x in this.additionalFields){
                obj[this.additionalFields[x]] = "";
            }
            return obj;
        },
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        }
    },

    // async mounted() {
    //     console.log("mounted");
    // },
    watch: {
        dialog (val) {
            console.log("dialog",this.dialog,this.editedItem);
            if(val == true){
                // show
                this.masterEditedItem = JSON.parse(JSON.stringify(this.editedItem));
            }
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        dialogImportCSV (val) {
            val || this.closeImportCSV()
        },
    },

    created () {
        // this.initialize()
        this.davinciUrl = this.$store.getters.deployment.dvURL;
        // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        if (this.davinciUrl.indexOf("davincistage") >= 0)
            this.server = "stage";
        else
            this.server = "prod";
    },

    mounted(){
        // if (this.locationMode=='beacons') {
        //     //this.endPoint = 'card-beacons';
        //     this.defaultItem = this.defaultBeacon;
        // } else {
        //     //this.endPoint = 'card-locations';
        //     this.defaultItem = this.defaultGeofence;
        // }
        // for(let x in this.additionalFields){
        //     this.defaultItem[this.additionalFields[x]] = "";
        // }
    },

    methods: {
        async initialize (acctKey, passKey) {
            if (this.locationMode=='geofences') {
                await this.loadConfig(passKey);
            }
            await this.loadLocationMapping(passKey);
            await this.setDefSuppression(this.account);
            this.locationDataLoading = true;
            this.acctKey = acctKey;
            this.passKey = passKey;
            console.log('locationMode', this.locationMode,this.acctKey,this.passKey);
            let ret = await addThisPass.getLocationData(this.acctKey,this.passKey,this.locationMode);
            console.log(ret.data);
            this.locations = this.correctData(ret.data);
            this.locationDataLoading = false;
        },
        displayRedirect(redirect) {
            //if (!(redirect && redirect=='1' && redirect=='2' && redirect=='3')) return '';
            let index = this.redirect.findIndex(option => option.value == redirect);
            return (index>=0 && this.redirect[index].text) ? this.redirect[index].text : '';
        },
        getListServiceAxios(){
            if(Object.keys(this.masterHeader).length ==0){
                return null;
            }else{
                return this.getCleanAxios();
            }
        },
        async loadConfig(passKey){
            var ret = await this.appUIGet(this.app.passUI.geofencing,{},{},this.masterHeader,this.getListServiceAxios());
            console.log("geofencing",ret);
            for(var x in ret){
                var data = ret[x];
                if(data.definition.passKey == passKey){
                    this.geofencingConfig = data;
                    return;
                }
            }
            // give it a default
            this.geofencingConfig = {
                definition: {
                    passKey: passKey,
                    maxRange: this.maxRange,
                }
            }
        },

        async loadLocationMapping(passKey){
            var ret = await this.appUIGet(this.app.passUI.locations,{},{},this.masterHeader,this.getListServiceAxios());
            console.log("loadLocationMapping",ret);
            this.locationMapping = {};
            for(var x in ret){
                let item = ret[x];
                this.locationMapping[item.definition.key] = item;
            }
        },

        async setDefSuppression(accountID){
            var ret = await axios.get(this.passUIEndpoint+`?cmd=getMaster&stage=${this.server}`);
            console.log("loadData", accountID, ret.data);
            let accountsData = ret.data.result.definition.data;
            let defSuppression = 360;
            for(let x in accountsData){
                if (accountsData[x].acctID == accountID) {
                    defSuppression = accountsData[x].defSuppression;
                    break;
                }
            }
            this.defaultBeacon.SUPPRESSION = defSuppression;
            this.defaultGeofence.SUPPRESSION = defSuppression;
        },

        locationRowClick(item) {
            this.editItem(item);
        },

        editItem (item) {
            this.editedIndex = this.locations.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.editMode = true;
            this.$nextTick(() => {
                this.$refs.addressX.setInitAddress(item['LOCATION']);
            });
        },

        deleteItem (item) {
            this.editedIndex = this.locations.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.locations.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        async closeClick() {
            if(this.canSave){
                let result = await this.$swal.fire({
                    title: 'Are you sure you want to close this without saving your work?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    showCancelButton: true,
                    showConfirmButton: true,
                });
                if(result.isConfirmed){
                    this.close();            
                }
            }else{
                this.close();     
            }
        },
        close () {
            this.dialog = false;
            this.editMode = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async importCSVConfirm () {
            console.log("importCSVConfirm", this.localFileInput); // e == this.localFileInput
            if(this.localFileInput == null){
                return;
            }
            if (this.localFileInput.name.indexOf(".csv")<0) {
                //alert("Invalid file type");
                //this.localFileInput = undefined;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid file type',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return;
            }
            let content = await utils.readTextFileAsync(this.localFileInput);
            console.log('content: ', content);
            let contentLines = content.split(/\r?\n/);
            let headers = this.headerCSVStringToArray(contentLines[0]);
            if (!this.validateHeaders(headers)) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Required fields not found',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return;
            }
            let contentJSON = this.csvToJSON(contentLines);
            console.log('contentJSON:', contentJSON);
            let locations = this.mapDisplayName(contentJSON, true);
            console.log('locations:', locations);
            locations = this.correctData(locations);            
            console.log('corrected locations:', locations);
            this.closeImportCSV();
            this.$swal.fire({
                title: 'Importing locations',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            for (let i=0; i<locations.length; i++) {
                this.editedItem = locations[i];
                await this.save('import');
            }
            this.initialize(this.acctKey, this.passKey);
            this.$swal.close();
        },

        closeImportCSV () {
            this.dialogImportCSV = false;
        },

        headerCSVStringToArray(inputString) {
            // Split the string by comma
            let parts = inputString.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

            // Map over the parts to remove text inside parentheses and trim whitespace
            let result = parts.map(part => part.split('(')[0].trim());

            return result;
        },

        validateHeaders(headers){
            console.log("validateHeaders", JSON.stringify(headers));
            let csvHeader = this.csvFields[this.locationMode];
            console.log("csvHeader", JSON.stringify(csvHeader));
            let ret = true;
            for(let i=0; i<csvHeader.length; i++){
                let hash = csvHeader[i];
                console.log("hash", JSON.stringify(hash));
                if (!hash.required) continue;
                console.log("hash2", JSON.stringify(hash));
                if (headers.indexOf(hash.displayName)<0){
                    ret = false;
                    break;
                }
            }
            return ret;
        },

        genKeyFromLocation(item){
            console.log("genKeyFromLocation",JSON.stringify(item));
            let hash = `${item["LOCATION LATITUDE"]},${item["LOCATION LONGITUDE"]}`;
            if("UUID" in item){
                hash = `${item["UUID"]}.${item["MAJOR"]}.${item["MINOR"]}`;
            }
            return hash;
        },
        async save(caller='dialog') {
            console.log('editedItem1:', this.editedItem);
            if (caller == 'dialog') {
                if ((this.editedItem['REDIRECT'] == '2' || this.editedItem['REDIRECT'] == '3') && this.editedItem['REDIRECT URL'] == '') {
                    return;
                }
                let result = await this.$swal.fire({
                    title: 'Are sure you want to set up this messaging?',
                    icon: 'warning',
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    showCancelButton: true,
                    showConfirmButton: true,
                });
                if(result.isConfirmed){
                    // this.close();            
                } else {
                    return;
                }
                if (this.editedIndex > -1) {
                    // update
                    Object.assign(this.locations[this.editedIndex], this.editedItem)
                } else {
                    // add new 
                    this.locations.push(this.editedItem)
                }
            }
            let key = this.genKeyFromLocation(this.editedItem);
                        
            console.log('editedItem2:', this.editedItem);
            let locationData = {
                key: key,
                name: this.editedItem.name,
                image: this.editedItem.image,
            }
            if (this.locationMode == 'beacons') locationData['physicalID'] = this.editedItem.physicalID;
            // check if we need to save 
            if(this.masterEditedItem.key != locationData.key ||
                this.masterEditedItem.name != locationData.name ||
                this.masterEditedItem.image != locationData.image ||
                this.masterEditedItem.physicalID != locationData.physicalID
            ){
                if(this.editedItem._id == ""){
                    console.log("save to AppUI",this.editedItem);
                    let saveRet = await this.appUIPost(this.app.passUI.locations,this.passKey,locationData)
                    console.log("saveRet",saveRet);
                }else{
                    // console.log("update to AppUI",this.editedItem.key,this.editedItem._id)
                    // update mapping 
                    let item = this.locationMapping[this.editedItem.key];
                    item.definition = locationData;
                    console.log(item);
                    // update storage
                    let updateRet = await this.appUIUpdate(this.editedItem._id,locationData);
                    console.log("updateRet",updateRet);
                }
            }else{
                console.log("locationData not change, skip save appUI",locationData);
            }
            
            // send tp AddThisPass
            await this.sendLocationToAddThisPass(this.editedItem, caller);    
        },
        async sendLocationToAddThisPass(item, caller='dialog'){
            if (caller=='dialog') {
                this.$swal.fire({
                    title: 'Saving location',
                    html: `<span></span>`,
                });
                this.$swal.showLoading();
            }
            let locations = [item];
            let csvContent = this.jsonToCSV(this.removeAdditionalField(locations));
            console.log("sendToAddThisPass",csvContent);
            console.log("DEBUG SKIP");
            // if(locations){
            //     throw "xxxx";
            // }
            let formData = new FormData();           
            formData.append('import_file', new Blob([csvContent], { type: 'text/csv' }), 'locations.csv');
            console.log(this.acctKey, this.passKey, formData);
            var ret = await addThisPass.postCSVFile(this.endPoint, this.acctKey, this.passKey, formData);
            console.log("ret: ", ret, this.locationMode);
            if (this.locationMode=='geofences') {
                ret = await addThisPass.setMainSettings(this.acctKey, this.passKey, {geo_distance: this.geofencingConfig.definition.maxRange});
                console.log("ret: ", ret);
            }
            console.log('caller:', caller);
            if (caller == 'dialog') {
                this.$swal.close();
                this.close();
            }
        },
        async addNewLocationClick(){
            this.dialog = true;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.$nextTick(() => {
                this.$refs.addressX.setInitAddress(this.editedItem['LOCATION']);
            });
        },

        async importCSVClick(){
            this.dialogImportCSV = true;
            console.log(this.acctKey, this.passKey);
        },

        downloadAllClick(){
            console.log("Downloading",this.locations);
            let csvContent = this.jsonToCSV(this.mapDisplayName(this.locations));
            console.log("csvContent",csvContent);
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            // Create a temporary anchor element
            const link = document.createElement("a");
            if (link.download !== undefined) {
                // Set the URL to the blob
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", this.locationMode+"-locations.csv");

                // Append the anchor to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up: remove the anchor and revoke the blob URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                console.error("Anchor element's 'download' attribute is not supported in this browser.");
            }
        },

        refreshTaskClick() {
            this.initialize(this.acctKey, this.passKey);
        },

        jsonToCSV(jsonData) {
            // Convert JSON to CSV
            const headers = Object.keys(jsonData[0]).join(",");
            const csvData = jsonData.map(obj => {
                return Object.values(obj).map(val => {
                    // If the value contains a comma, wrap it in double quotes
                    if (val.toString().includes(",")) {
                        return `"${val}"`;
                    }
                    return val;
                }).join(",");
            }).join("\n");
            return headers + "\n" + csvData;
        },

        csvToJSON(csvData) {
            const headers = this.headerCSVStringToArray(csvData[0]);
            let jsonData = csvData.slice(1).map(line => {
                if (line.trim()!='') {
                    const json = {};
                    let row = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                    headers.forEach((header, index) => {
                        json[header] = row[index] ? row[index].replace(/"/g, '').trim() : '';
                    });
                    return json;
                }
            }).filter(element => element !== undefined);
            return jsonData;
        },

        correctData(jsonArray) {
            if (addThisPass.isEmpty(jsonArray)) return [];
            // Clone the original array to avoid mutating the original data
            // let defaultItem = JSON.parse(JSON.stringify(this.defaultBeacon))
            // if(this.locationMode == "geofences"){
            //     defaultItem = JSON.parse(JSON.stringify(this.defaultGeofence))
            // }
            console.log("correctData start:",JSON.stringify(this.defaultItem),this.locationMode);
            let modifiedJsonArray = jsonArray.map(obj => {
                // Create a shallow copy of the object to avoid modifying the original object
                obj = JSON.parse(JSON.stringify(obj)); // remove undefined fields
                console.log("obj:",obj);
                let newObj = { ...this.defaultItem, ...obj };    // make object sort as defaultItem
                console.log("newObj:",newObj);
                // Delete the specified keys from the object
                delete newObj['TOTAL VIEWS'];
                delete newObj['UNIQUE VIEWS'];
                // add additional fields
                // for(let x in this.additionalFields){
                //     newObj[this.additionalFields[x]] = "";
                // }
                // merge field from mapping 
                let key = this.genKeyFromLocation(newObj);
                console.log("key:",key);
                if(key in this.locationMapping){
                    let loc = this.locationMapping[key];
                    newObj._id = loc._id;
                    newObj = { ...newObj, ...Object.fromEntries(Object.entries(loc.definition).filter(([key, value]) => !newObj[key])) };
                    // newObj = {... newObj, ... loc.definition};
                }
                return newObj;
            });
            // console.log("locationMapping",this.locationMapping);
            // console.log("correctData end:",this.defaultItem,modifiedJsonArray);
            return modifiedJsonArray;
        },

        removeAdditionalField(locations){
            let ret = [];
            console.log(locations);
            for(let x in locations){
                let loc = JSON.parse(JSON.stringify( locations[x]));
                for(let y in this.additionalFields){
                    delete loc[this.additionalFields[y]];
                }
                ret.push(loc)
            }
            return ret;
        },

        mapDisplayName(locations, invert=false){
            let ret = [];
            console.log(locations);
            let csvFields = this.csvFields[this.locationMode];
            for(let x in locations){
                let loc = locations[x];
                let newLoc = {};
                for(let y in csvFields){
                    let field = csvFields[y];
                    if (invert) {
                        newLoc[field.codeName] = loc[field.displayName];
                    } else {
                        newLoc[field.displayName] = loc[field.codeName];
                    }
                }
                ret.push(newLoc)
            }
            // console.log("ret", ret);
            return ret;
        },

        async sendtoAddthispassClick () {
            if(this.locations.length > 0){
                let csvContent = this.jsonToCSV(this.removeAdditionalField(this.locations));
                console.log('csvContent: ', csvContent);
                let formData = new FormData();           
                formData.append('import_file', new Blob([csvContent], { type: 'text/csv' }), 'locations.csv');
                console.log(this.acctKey, this.passKey, formData);
                this.$swal.fire({
                    title: 'Upload locations',
                    html: `<span></span>`,
                });
                this.$swal.showLoading();
                var ret = await addThisPass.postCSVFile(this.endPoint, this.acctKey, this.passKey, formData);
                console.log("ret: ", ret, this.locationMode);
                // if (this.locationMode=='geofences') {
                //     ret = await addThisPass.setMainSettings(this.acctKey, this.passKey, {geo_distance: this.geofencingConfig.definition.maxRange});
                //     console.log("ret: ", ret);
                // }
                this.$swal.close();
            }
        },

        async saveMaxRange() {
            this.$swal.fire({
                title: 'Saving max distanse',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            // save maxRange to storage
            if(this.geofencingConfig._id == undefined){
                await this.appUIPost(this.app.passUI.geofencing,this.passKey,this.geofencingConfig.definition);
            }else{
                await this.appUIUpdate(this.geofencingConfig._id,this.geofencingConfig.definition);
            }
            let ret = await addThisPass.setMainSettings(this.acctKey, this.passKey, {geo_distance: this.geofencingConfig.definition.maxRange});
            console.log("ret: ", ret);
            this.canSaveMaxRange = false;
            this.$swal.close();
        },

        showSaveMaxRange() {
            console.log('showSaveMaxRange', this.geofencingConfig.definition.maxRange);
            if (this.geofencingConfig.definition.maxRange>=300 && this.geofencingConfig.definition.maxRange<=500) {
                this.canSaveMaxRange = true;
            } else {
                this.canSaveMaxRange = false;
            }
        },

        addressChange(address){    
            console.log("addressChange",address);
            this.editedItem.LOCATION = address;
        },

        getAddressData: function (addressData, placeResultData, id) {
            console.log(addressData,placeResultData,id);
            this.editedItem.LOCATION = placeResultData.formatted_address;
            this.editedItem['LOCATION LATITUDE'] = addressData.latitude;
            this.editedItem['LOCATION LONGITUDE'] = addressData.longitude;
        },

        showCropUI(mode,flgEdit = false) {
            // this.$refs.file.click();
            // this.$refs.cropper.chooseFile();
            console.log("showCropUI", mode,this.cropInited);
            this.cropUI.mode = mode;
            this.cropUI.flgEdit = flgEdit;
            // switch (mode) {
            //     case "image": {
            //         this.cropUI.width = 160;
            //         this.cropUI.maxWidth = 160*4;
            //         this.cropUI.height = 120;
            //         this.cropUI.maxHeight = null;
            //         break;
            //     }
            // }
            console.log("showCropUI",this.cropUI,"flgEdit",flgEdit);
            if(flgEdit == false){
                // brower new image
                this.$refs.fileInput.$refs.input.click()
            }else{
                this.initialCropImage = this.editedItem['image']
                this.cropUI.show = true;
            }
            return;

        },
    },
  }
</script>