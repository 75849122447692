<template>
    <v-container style="height: 100%; width: 100%;background: white;">
        <v-row class="mt-0">
            <v-col cols=8 style="line-height: 32px;">
                ADD ALERT PREFERENCE
            </v-col>
            <v-col cols=4 class="text-right">
                <v-tooltip top hide-on-click hide-on-hover open-on-hover :disabled="isTooltipDisabled">
                    <template v-slot:activator="{ on}">
                        <v-btn ref="refreshBtn" :disabled="preferences.length==0" v-on="on" small class="btn-icon mt-1 mr-2" @click="refreshTaskClick()"><v-icon>refresh</v-icon></v-btn>
                    </template>
                    Reload
                </v-tooltip>
                <!-- <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="importCSVClick()"><v-icon>upload_file</v-icon></v-btn>
                    </template>
                    Import CSV File
                </v-tooltip>
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="preferences.length==0" small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="downloadAllClick()"><v-icon>download</v-icon></v-btn>
                    </template>
                    Download All
                </v-tooltip> -->
                <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small class="btn-icon mt-1 mr-2" v-bind="attrs" v-on="on" @click="addNewPreferenceClick()"><v-icon>add</v-icon></v-btn>
                    </template>
                    Add New Preference
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="py-1">
            <v-col cols=12>
                <v-data-table :headers="headers" :items="preferences" :loading="dataLoading" @click:row="rowClick" :items-per-page="5" item-key="id" :single-select=true sort-by="preference" class="elevation-1" dense style="width: 100%;" height="30vh">
                    <template v-slot:no-data>
                        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                    </template>
                    <template v-slot:[`item.action`]="props">
                        <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(props.item)">edit</v-icon> 
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top hide-on-click hide-on-hover open-on-hover>
                            <template v-slot:activator="{ on , attrs}">
                                <v-icon @click.native.stop v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(props.item)">delete</v-icon> 
                            </template>
                            <span>Remove</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="400px">
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Address</v-btn>
            </template> -->
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field dense v-model="editedItem['preference']" label="Preference"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="canSave == false" color="blue darken-1" text @click="save('dialog')">Save</v-btn>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogImportCSV" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"></v-card-title>
                <v-card-text>
                    <v-row dense class="py-1">
                        <v-col cols="12">
                            <v-file-input ref="localFileInput" accept=".csv" hide-details class="pt-1 shrink" v-model="localFileInput" label="CSV" prepend-icon="upload_file" ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <a href="https://mfdavinci.s3.us-west-1.amazonaws.com/appData/file/geofences-sample.csv">download a file sample here</a>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="importCSVConfirm">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="closeImportCSV">Cancel</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete '{{editedItem['preference']}}'?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<style>
</style>
<style scoped>
</style>
<script>
 import axios from "axios";
 import utils from '../services/KCUtils.js'
 import kcMixins from "../services/KCMixins.js";
 import addThisPass from "../services/KCAddThisPass.js";
//  import KCGoogleAutoComplete from "../components/KCGoogleAutoComplete.vue";
// var crypto = require('crypto');

const NAME = "JSAlertPreference";

const serverMode="pass";
let config = utils.getServerConfig(serverMode);
import csvUtils from "../services/KCCSVUtils.js";

export default {
    name: NAME,
    mixins: [kcMixins],
    components: {
    },
    props: {
        masterHeader: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            /* mixins start */
            appName: "passUI",
            /* mixins end */
            davinciUrl: null,
            dialog: false,
            dialogDelete: false,
            dialogImportCSV: false,
            localFileInput: null,
            dataLoading: false,
            acctKey: '',
            passKey: '',
            headers: [
                { text: "ID", value: "id", sortable: true },
                { text: "Preference", value: "preference", sortable: true },
                { text: '', value: 'action', sortable: false, align: 'center' }
            ],
            preferences: [],
            default: {
                "id": "",
                "preference": ""
            },
            editedItem: {},
            editedIndex: -1,
            masterEditedItem: {},
            csvFields: [
                { displayName: "Preference", codeName: "preference", required: true }
            ],
            isTooltipDisabled: false
        };
    },
    computed: {
        account: function () {
            //console.log('user object: ', this.$store.getters.user);
            return this.$store.getters.user.account;
        },
        canSave(){
            if(JSON.stringify(this.editedItem) == JSON.stringify(this.masterEditedItem)){
                return false
            }
            return true;
        },
        formTitle () {
            return this.editedIndex === -1 ? 'New Preference' : 'Edit Preference - '+this.editedItem.id;
        },
    },
    methods: {
        async initialize (acctKey, passKey) {
            this.acctKey = acctKey;
            this.passKey = passKey;
            this.dataLoading = true;
            await this.loadPreferences(this.passKey);
            this.dataLoading = false;
        },
        rowClick(item) {
            this.editItem(item);
        },

        editItem (item) {
            console.log('edit item',item);
            this.editedIndex = this.preferences.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.editMode = true;
        },

        async deleteItem (item) {
            console.log('delete item',item);
            this.editedIndex = this.preferences.indexOf(item);
            this.editedItem = Object.assign({}, item);
            // this.dialogDelete = true
            this.isTooltipDisabled = true;
            var result = await this.$swal.fire({
                title: `Are you sure you want to delete '${this.editedItem.preference}'?`,
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                focusConfirm: true,
                ... csvUtils.getSwalYesNoOption()
            });
            console.log(result);
            this.isTooltipDisabled = false;
            if (result.isConfirmed) {
                this.deleteItemConfirm();
            }
        },

        async deleteItemConfirm () {
            let preferenceData = {
                id: (this.editedIndex > -1) ? this.editedItem.id : '',
                preference: this.editedItem.preference
            }
            let ret = await addThisPass.alterPreference(this.acctKey, this.passKey, 'delete', preferenceData);
            if (ret['SUCCESS']) {
                let deleteRet = await this.appUIDelete(this.editedItem._id);
                console.log("deleteRet",deleteRet);
                this.preferences.splice(this.editedIndex, 1);
                this.closeAll();
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: ret['ERROR'],
                    confirmButtonText: "Ok",
                });
                return;
            }
        },

        close () {
            this.dialog = false;
            this.editMode = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false;
        },

        closeAll () {
            this.dialogDelete = false;
            this.close();
        },
        async addNewPreferenceClick(){
            this.dialog = true;
            this.editedItem = Object.assign({}, this.default);
        },
        async save(caller='dialog') {
            console.log('editedItem:', this.editedItem);
            let preferenceData = {
                id: (this.editedIndex > -1) ? this.editedItem.id : '',
                preference: this.editedItem.preference
            }
            if(this.masterEditedItem.id != preferenceData.id ||
                this.masterEditedItem.preference != preferenceData.preference
            ){
                if (this.editedIndex > -1) {
                    // update
                    let ret = await addThisPass.alterPreference(this.acctKey, this.passKey, 'edit', preferenceData);
                    if (ret['SUCCESS']) {
                        // console.log("update to AppUI",this.editedItem.id,this.editedItem._id);
                        Object.assign(this.preferences[this.editedIndex], this.editedItem);
                        let updateRet = await this.appUIUpdate(this.editedItem._id,preferenceData);
                        console.log("updateRet",updateRet);
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: ret['ERROR'],
                            confirmButtonText: "Ok",
                        });
                        return;
                    }
                } else {
                    // add new
                    let ret = await addThisPass.alterPreference(this.acctKey, this.passKey, 'add', preferenceData);
                    if (ret['SUCCESS']) {
                        preferenceData.id = ret['ID']; 
                        console.log("save to AppUI",this.editedItem);
                        let saveRet = await this.appUIPost(this.app.passUI.preferences,this.passKey,preferenceData);
                        console.log("saveRet",saveRet);
                        this.editedItem.id = preferenceData.id;
                        this.editedItem._id = saveRet._id;
                        this.preferences.push(this.editedItem);
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: ret['ERROR'],
                            confirmButtonText: "Ok",
                        });
                        return;
                    }
                }
                console.log('preferences:', this.preferences);
            }else{
                console.log("preferenceData not change, skip save appUI",preferenceData);
            }
            this.close();
        },

        async loadPreferences(passKey){
            var ret = await this.appUIGet(this.app.passUI.preferences,{name: passKey},{},this.masterHeader,this.getListServiceAxios());
            console.log("loadPreferences",ret);
            this.preferences = [];
            for(var x in ret){
                let item = ret[x];
                this.preferences.push({
                    _id: item._id,
                    id: item.definition.id,
                    preference: item.definition.preference
                });
            }
        },
        getListServiceAxios(){
            if(Object.keys(this.masterHeader).length ==0){
                return null;
            }else{
                return this.getCleanAxios();
            }
        },
        refreshTaskClick() {
            this.initialize(this.acctKey, this.passKey);
        },

        downloadAllClick(){
            console.log("Downloading",this.preferences);
            let csvContent = this.jsonToCSV(this.mapDisplayName(this.preferences));
            console.log("csvContent",csvContent);
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            // Create a temporary anchor element
            const link = document.createElement("a");
            if (link.download !== undefined) {
                // Set the URL to the blob
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "Preferences.csv");

                // Append the anchor to the body and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up: remove the anchor and revoke the blob URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } else {
                console.error("Anchor element's 'download' attribute is not supported in this browser.");
            }
        },

        mapDisplayName(data, invert=false){
            let ret = [];
            console.log(data);
            for(let x in data){
                let loc = data[x];
                let newLoc = {};
                for(let y in this.csvFields){
                    let field = this.csvFields[y];
                    if (invert) {
                        newLoc[field.codeName] = loc[field.displayName];
                    } else {
                        newLoc[field.displayName] = loc[field.codeName];
                    }
                }
                ret.push(newLoc)
            }
            // console.log("ret", ret);
            return ret;
        },

        jsonToCSV(jsonData) {
            // Convert JSON to CSV
            const headers = Object.keys(jsonData[0]).join(",");
            const csvData = jsonData.map(obj => {
                return Object.values(obj).map(val => {
                    // If the value contains a comma, wrap it in double quotes
                    if (val.toString().includes(",")) {
                        return `"${val}"`;
                    }
                    return val;
                }).join(",");
            }).join("\n");
            return headers + "\n" + csvData;
        },

        importCSVClick(){
            this.dialogImportCSV = true;
            console.log(this.passKey);
        },

        closeImportCSV () {
            this.dialogImportCSV = false;
        },

        async importCSVConfirm () {
            console.log("importCSVConfirm", this.localFileInput); // e == this.localFileInput
            if(this.localFileInput == null){
                return;
            }
            if (this.localFileInput.name.indexOf(".csv")<0) {
                //alert("Invalid file type");
                //this.localFileInput = undefined;
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invalid file type',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return;
            }
            let content = await utils.readTextFileAsync(this.localFileInput);
            console.log('content: ', content);
            let contentLines = content.split(/\r?\n/);
            let headers = this.headerCSVStringToArray(contentLines[0]);
            if (!this.validateHeaders(headers)) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Required fields not found',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    console.log("Clear");
                    //this.$refs.localFileInput.value = null;
                    this.$refs.localFileInput.reset();
                    // this.fileInputKey++;    // clear this.localFileInput
                    this.localFileInput  = null;
                })
                return;
            }
            let contentJSON = this.csvToJSON(contentLines);
            console.log('contentJSON:', contentJSON);
            let preferences = this.mapDisplayName(contentJSON, true);
            console.log('preferences:', preferences);
            preferences = this.correctData(preferences);            
            console.log('corrected preferences:', preferences);
            this.closeImportCSV();
            this.$swal.fire({
                title: 'Importing preferences',
                html: `<span></span>`,
            });
            this.$swal.showLoading();
            for (let i=0; i<preferences.length; i++) {
                this.editedItem = preferences[i];
                await this.save('import');
            }
            this.initialize(this.passKey);
            this.$swal.close();
        },

        headerCSVStringToArray(inputString) {
            // Split the string by comma
            let parts = inputString.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

            // Map over the parts to remove text inside parentheses and trim whitespace
            let result = parts.map(part => part.split('(')[0].trim());

            return result;
        },

        validateHeaders(headers){
            console.log("validateHeaders", JSON.stringify(headers));
            let csvHeader = this.csvFields[this.locationMode];
            console.log("csvHeader", JSON.stringify(csvHeader));
            let ret = true;
            for(let i=0; i<csvHeader.length; i++){
                let hash = csvHeader[i];
                console.log("hash", JSON.stringify(hash));
                if (!hash.required) continue;
                console.log("hash2", JSON.stringify(hash));
                if (headers.indexOf(hash.displayName)<0){
                    ret = false;
                    break;
                }
            }
            return ret;
        },
    },
    created() {
        // this.davinciUrl = this.$store.getters.deployment.dvURL;
        // // alert(`in created(): davinciUrl=${this.davinciUrl}`);
        // if (this.davinciUrl.indexOf("davincistage") >= 0)
        //     this.server = "stage";
        // else
        //     this.server = "prod";
        // // this.endPoint = config.endPoints.mindfireOne + `/api/card/${this.server}`;
    },
    destroyed() {
    },
    async mounted() {
    },
    beforeUnmount() { 
    },
    watch: {
        account: {
            async handler(val) {
            },
            deep: true
        },
        dialog (val) {
            console.log("dialog",this.dialog,this.editedItem);
            if(val == true){
                // show
                this.masterEditedItem = JSON.parse(JSON.stringify(this.editedItem));
            }
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        dialogImportCSV (val) {
            val || this.closeImportCSV()
        },
    },
};
</script>